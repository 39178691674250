import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/Artwork.png'
import _imports_1 from '@/assets/icons/ic_btn_xlsx_download_file.svg'
import _imports_2 from '@/assets/icons/ic_info_solid.svg'
import _imports_3 from '@/assets/icons/ic_search.svg'
import _imports_4 from '@/assets/icons/ic_download.svg'
import _imports_5 from '@/assets/icons/illus_empty_file.svg'
import _imports_6 from '@/assets/icons/ic_history.svg'


const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","min-height":"100vh"} }
const _hoisted_2 = { style: {} }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"column","min-height":"100vh"} }
const _hoisted_4 = { style: {"padding":"16px 20px"} }
const _hoisted_5 = { class: "upload-header-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "layout-main-search-table" }
const _hoisted_8 = { class: "upload-header-container" }
const _hoisted_9 = {
  class: "fg-bold",
  style: {"font-size":"16px","font-weight":"bold","line-height":"24px"}
}
const _hoisted_10 = {
  class: "fg-normal",
  id: "total-upload-list-show",
  style: {"font-size":"16px","font-weight":"normal","color":"#1b5faa","line-height":"24px"}
}
const _hoisted_11 = { style: {"display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_12 = { style: {"display":"flex","justify-content":"flex-end"} }
const _hoisted_13 = {
  key: 0,
  style: {"z-index":"9999","position":"absolute","top":"300px","background-color":"#fff","box-shadow":"0px 4px 12px rgba(0, 0, 0, 0.1)","border-radius":"8px","width":"230px"}
}
const _hoisted_14 = { style: {"margin":"16px"} }
const _hoisted_15 = { style: {"display":"flex","justify-content":"flex-end"} }
const _hoisted_16 = { class: "table-work-schedule" }
const _hoisted_17 = {
  key: 0,
  class: "no-data-message"
}
const _hoisted_18 = { style: {"margin-left":"16px"} }
const _hoisted_19 = { class: "detail-table" }
const _hoisted_20 = { class: "detail-table" }
const _hoisted_21 = { class: "detail-table" }
const _hoisted_22 = { class: "detail-table" }
const _hoisted_23 = {
  key: 0,
  src: _imports_6,
  alt: "My Icon",
  width: "20",
  height: "20"
}
const _hoisted_24 = { key: 0 }
const _hoisted_25 = ["src", "width", "height", "onClick"]
const _hoisted_26 = ["src", "width", "height", "onClick"]

import { ref, onMounted, computed, watch, onBeforeMount, nextTick } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { th } from 'date-fns/locale';
import { useUpLoadListStore } from '@/stores/GetUploadList';
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useReloadTableUploadStore } from '@/stores/ReloadUploadList';
import { UploadList } from '@/models/UploadListResopnse';
import { UploadListPayload } from '@/models/UploadListPlayload';
import { DownloadFilePayload } from '@/models/DownloadFilePayload';
import UploadDialog from '@/components/UploadDialog.vue';
import NotiAppBar from '@/components/NotiAppBar.vue';
import { useDownloadFileStore } from '@/stores/DownloadFile';
import { useEmployeeRoleDetailStore } from '@/stores/EmployeeRole';
import { useEmployeeDetailStore } from '@/stores/EmployeeDetail';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import { useUpLoadFileStore } from '@/stores/UploadFile';

interface Header {
  title: string;
  value: string;
  key?: string;
  width?: string;
  align?: string;
  class?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadFileList',
  setup(__props) {

const currentDate = new Date();
//  const setupUpload = storeUploadSetUp.periodDetail;
// uploadble.value = setupUpload?.period?.canUpload || false;

const monthMapNumber: { [key: number]: string } = {
  1: 'มกราคม',
  2: 'กุมภาพันธ์',
  3: 'มีนาคม',
  4: 'เมษายน',
  5: 'พฤษภาคม',
  6: 'มิถุนายน',
  7: 'กรกฎาคม',
  8: 'สิงหาคม',
  9: 'กันยายน',
  10: 'ตุลาคม',
  11: 'พฤศจิกายน',
  12: 'ธันวาคม',
};

const showDialogDownLoadFile = ref(false);
const selectedTypeFile = ref('');
const thLocale: any = th;
const uploadListData = ref<UploadList[]>([]);
const searchProject = ref('');
const hasFetchedData = ref(false);
const uploadble = ref(false);
const storeUploadList = useUpLoadListStore();
const storeUploadSetUp = useUpLoadSetUpStore();
const employeeRoleStore = useEmployeeRoleDetailStore();
const downloadFileStore = useDownloadFileStore();
const storeUpLoadFile = useUpLoadFileStore();
const storeReloadTableUpload = useReloadTableUploadStore();
const isClickDownLoadFile = ref(false);
const isLoadingFillter = ref(true);
const showUploadDialog = ref(false);
const userRole = ref(sessionStorage.getItem('userRole'));
const employeeDetailStore = useEmployeeDetailStore();
const userEmail = ref(sessionStorage.getItem('userEmail'));
const date = ref({
  month: 0, // ค่าเริ่มต้นเป็น 0
  year: 0, // ค่าเริ่มต้นเป็น 0
});
const payload: EmployeeDetailPayload = {
  email: userEmail.value ? [userEmail.value] : [''],
  empWorkStatus: '',
  empName: '',
  empLastName: '',
  companyCode: '',
  divisionCode: null,
  departmentCode: null,
  empGroupCode: '',
  pageNumber: 1,
};

onMounted(async () => {
  if (
    !hasFetchedData.value ||
    (storeUpLoadFile.isClick == true && storeUpLoadFile.isLoading == false)
  ) {
    await storeUploadSetUp.fetchUpLoadSetUpDetail();
    const currentPeriod = storeUploadSetUp.periodDetail;
    uploadble.value = currentPeriod?.period?.canUpload || false; // uploadble.value = true;
    date.value = {
      month: Number(currentPeriod?.period?.currentPeriodMonth ?? 0) - 1,
      year: Number(currentPeriod?.period?.currentPeriodYear ?? 0),
    };
    const empCode = sessionStorage.getItem('empCode') || '';
    if (empCode) {
      await fetchDetails(empCode);
    }
  }
});

/*onBeforeMount(async () => {
  await storeUploadSetUp.fetchUpLoadSetUpDetail();
  const currentPeriod = storeUploadSetUp.periodDetail;
  uploadble.value = currentPeriod?.period?.canUpload || false; // uploadble.value = true;
  date.value = {
    month: Number(currentPeriod?.period?.currentPeriodMonth ?? 0) - 1,
    year: Number(currentPeriod?.period?.currentPeriodYear ?? 0),
  };
}); */

const isReloadUploadList = computed(() => storeReloadTableUpload.isReload);
watch(isReloadUploadList, async (newValue, oldValue) => {
  if (newValue === true) {
    hasFetchedData.value = false;
    const empCode = sessionStorage.getItem('empCode') || '';
    let UpLoadListDetailPayload: UploadListPayload = {
      empCode: empCode,
      fileMonth: date.value.month + 1,
      fileYear: date.value.year,
    };
    await storeUploadList.fetchUpLoadListDetail(UpLoadListDetailPayload);
    if (Array.isArray(storeUploadList?.uploadList)) {
      uploadListData.value = storeUploadList.uploadList;
    } else {
      uploadListData.value = [];
    }
    storeReloadTableUpload.ResetReload();
  }
});
const filteredUploadList = computed(() => {
  const selectedDate = date.value;
  const selectedMonth = selectedDate.month + 1;
  const selectedYear = selectedDate.year;

  const filteredList = uploadListData.value
    .filter((item) => {
      isLoadingFillter.value = true;
      const matchesProject =
        searchProject.value.trim() === '' ||
        item.projectName
          .toLowerCase()
          .includes(searchProject.value.toLowerCase());
      const matchesDate =
        item.fileMonth == selectedMonth && item.fileYear == selectedYear;
      isLoadingFillter.value = false;
      return matchesProject && matchesDate;
    })
    .map((item) => {
      return {
        ...item,
        fileMonthName: monthMapNumber[item.fileMonth],
      };
    });

  isLoadingFillter.value = false;
  return filteredList;
});

const filteredHeaders = computed(() => {
  if (userRole.value !== 'Admin') {
    return headers.value.filter((header) => header.value !== 'fileCSVLink');
  }
  return headers.value;
});
const onDateChange = async () => {
  hasFetchedData.value = false;
  const empCode = sessionStorage.getItem('empCode') || '';
  await fetchDetails(empCode);
};
const closeDownloadDialog = () => {
  if (showDialogDownLoadFile.value == true) {
    showDialogDownLoadFile.value = false;
  }
  selectedTypeFile.value = '';
};
const downloadTemplate = () => {
  closeDownloadDialog();
};
const openUploadDialog = () => {
  closeDownloadDialog();
  showUploadDialog.value = true;
};
const clickTable = () => {
  closeDownloadDialog();
};
const clickSearch = () => {
  closeDownloadDialog();
};
const clickDateTimeSelect = () => {
  closeDownloadDialog();
};

const totalUploadList = computed(() => {
  return filteredUploadList?.value?.length || 0;
});
const isLoading = computed(
  () =>
    storeUploadSetUp.isLoading ||
    storeUploadList.isLoading ||
    employeeDetailStore.isLoading ||
    employeeRoleStore.isLoading,
);

const isDownloadFile = computed(() => downloadFileStore.isLoading);
const error = computed(
  () =>
    storeUploadSetUp.error || storeUploadList.error || employeeRoleStore.error,
);

const fetchDetails = async (empCode: string) => {
  if (hasFetchedData.value) return;
  try {
    let UpLoadListDetailPayload: UploadListPayload = {
      empCode: empCode,
      fileMonth: date.value.month + 1,
      fileYear: date.value.year,
    };
    console.log('UpLoadListDetailPayload::>', UpLoadListDetailPayload);
    await storeUploadList.fetchUpLoadListDetail(UpLoadListDetailPayload);
    if (Array.isArray(storeUploadList?.uploadList)) {
      uploadListData.value = storeUploadList.uploadList;
    } else {
      uploadListData.value = [];
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('fetchDetails_LOADING...', isLoading.value);
    hasFetchedData.value = true;
  }
};

const options = ref({
  page: 1,
  itemsPerPage: 10,
  sortBy: ['createDateValue'],
  sortDesc: [true],
});

const headers = ref<Header[]>([
  {
    title: 'โครงการ',
    value: 'projectName',
    key: 'projectName',
    width: '25%',
    align: 'start',
  },
  { title: 'เดือน', value: 'fileMonthName', width: '9%', align: 'start' },
  { title: 'ปี', value: 'fileYear', width: '9%', align: 'start' },
  {
    title: 'วันที่อัปโหลด',
    value: 'createDate',
    key: 'createDate',
    width: '15%',
    align: 'start',
  },
  { title: 'ผู้อัปโหลด', value: 'createBy', width: '36%', align: 'start' },
  { title: '', value: 'isFileLasted', width: '2%', align: 'center' },
  { title: '', value: 'fileCSVLink', width: '2%', align: 'center' },
  { title: '', value: 'fileExcelLink', width: '2%', align: 'center' },
]);

let isDownloading = false;
async function downloadFileTable(fileLink: string | undefined) {
  if (!fileLink || isDownloading) return;

  isDownloading = true;
  try {
    closeDownloadDialog();
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = '';
    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed:', error);
  } finally {
    isDownloading = false;
  }
}

async function downLoadFile() {
  isClickDownLoadFile.value = true;
  let downloadFilePayload: DownloadFilePayload = {
    fileMonth: date.value.month + 1,
    fileYear: date.value.year,
    fileType: selectedTypeFile.value,
  };
  showDialogDownLoadFile.value = false;
  await downloadFileStore.fetchFileDownload(downloadFilePayload);
  isClickDownLoadFile.value = false;
  selectedTypeFile.value = '';
}

const dataUpload = ref([
  {
    projectName: 'สารุญสิริ บางใหญ่',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '11/11/2024 17:44',
    createBy: 'ไพฑูรย์ รุ่งฤดี',
    isFileLasted: true,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
  {
    projectName: 'สารุญสิริ บางนา',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '10/11/2024 17:44',
    createBy: 'อนันต์เป็ด สนุกดี',
    isFileLasted: false,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_hover = _resolveComponent("v-hover")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NotiAppBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _cache[19] || (_cache[19] = _createStaticVNode("<div style=\"display:flex;justify-content:space-between;\" data-v-f4f7edc4><div class=\"layout-text\" data-v-f4f7edc4><p class=\"fg-bold\" style=\"font-size:28px;font-weight:bold;line-height:40px;\" data-v-f4f7edc4> ยินดีต้อนรับสู่ระบบ Upload Work Schedule </p><p style=\"font-size:16px;font-weight:normal;line-height:24px;\" data-v-f4f7edc4> ระบบอัปโหลดตารางการทำงาน </p></div><div data-v-f4f7edc4><img src=\"" + _imports_0 + "\" alt=\"My Icon\" data-v-f4f7edc4></div></div><div style=\"border-bottom:1px solid;color:#c0c0c0;\" data-v-f4f7edc4></div>", 2)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[8] || (_cache[8] = _createElementVNode("p", {
              class: "fg-bold",
              style: {"font-size":"20px","font-weight":"bold","line-height":"32px"}
            }, " รายการอัปโหลดตารางการทำงาน ", -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_v_btn, {
                variant: "outlined",
                id: "btn-download-template",
                href: "/template/Template-UploadWork.xlsx",
                download: "",
                onClick: downloadTemplate,
                class: "fg-bold",
                style: {"font-size":"14px","font-weight":"bold","margin-right":"8px","color":"#162e46","line-height":"20px","letter-spacing":"normal","word-spacing":"normal","width":"182px"}
              }, {
                prepend: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    src: _imports_1,
                    alt: "My Icon",
                    width: "24",
                    height: "24"
                  }, null, -1)
                ])),
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createTextVNode(" ดาวน์โหลดเทมเพลต "))
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                variant: "tonal",
                class: "custom-btn-disable fg-bold",
                id: "btn-upload-file",
                disabled: !uploadble.value,
                onClick: openUploadDialog,
                style: _normalizeStyle([{"font-size":"14px","line-height":"20px","font-weight":"bold","background-color":"#1b5faa","color":"#ffffff","width":"111px","letter-spacing":"normal","word-spacing":"normal"}, {
                  backgroundColor: uploadble.value ? '#1b5faa' : '#d7d8d9',
                  color: uploadble.value ? '#ffffff' : '#A2A4A7',
                  opacity: uploadble.value ? 1 : 0.8,
                }])
              }, {
                prepend: _withCtx(() => [
                  _createElementVNode("img", {
                    src: 
                      uploadble.value
                        ? require('@/assets/icons/ic_upload_white.svg')
                        : require('@/assets/icons/ic_upload_disable.svg')
                    ,
                    alt: "My Icon",
                    width: "24",
                    height: "24",
                    color: "#A2A4A7"
                  }, null, 8, _hoisted_6)
                ]),
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createTextVNode(" อัปโหลด "))
                ]),
                _: 1
              }, 8, ["disabled", "style"])
            ])
          ])
        ]),
        _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"header-warning\" data-v-f4f7edc4><div style=\"margin-left:16px;width:24px;height:24px;align-items:center;\" data-v-f4f7edc4><img src=\"" + _imports_2 + "\" width=\"24\" height=\"24\" data-v-f4f7edc4></div><div style=\"margin-left:8px;\" data-v-f4f7edc4><p style=\"font-size:14px;line-height:20px;\" data-v-f4f7edc4> สามารถอัปโหลดไฟล์ได้ในวันที่ 1-5 เท่านั้น </p></div></div>", 1)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_9, [
                _cache[9] || (_cache[9] = _createTextVNode(" จำนวนรายการ : ")),
                _createElementVNode("span", _hoisted_10, _toDisplayString(totalUploadList.value), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_v_text_field, {
                modelValue: searchProject.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchProject).value = $event)),
                id: "search-project",
                placeholder: "ค้นหาจากชื่อโครงการ",
                variant: "outlined",
                density: "compact",
                style: {"width":"360px","height":"40px","font-size":"10px"},
                class: "custom-text-field",
                onClick: clickSearch
              }, {
                "prepend-inner": _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("div", { style: {"width":"20px","height":"20px"} }, [
                    _createElementVNode("img", {
                      style: {"align-items":"center"},
                      height: "20",
                      width: "20",
                      src: _imports_3
                    })
                  ], -1)
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_unref(Datepicker), {
                id: "search-period",
                modelValue: date.value,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((date).value = $event)),
                  onDateChange
                ],
                clearable: false,
                "format-locale": _unref(thLocale),
                language: "th",
                format: "MMMM yyyy",
                "month-picker": "",
                style: {"width":"167px","margin-left":"8px"},
                onOpen: clickDateTimeSelect
              }, null, 8, ["modelValue", "format-locale"]),
              (userRole.value == 'Admin')
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    variant: "tonal",
                    id: "btn-download-file",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (showDialogDownLoadFile.value = true)),
                    class: "fg-bold",
                    style: {"font-size":"14px","line-height":"20px","letter-spacing":"normal","word-spacing":"normal","font-weight":"bold","background-color":"#1b5faa","height":"40px","width":"126px","margin-left":"8px","color":"#ffffff"}
                  }, {
                    prepend: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("img", {
                        src: _imports_4,
                        alt: "My Icon",
                        width: "24",
                        height: "24"
                      }, null, -1)
                    ])),
                    default: _withCtx(() => [
                      _cache[12] || (_cache[12] = _createTextVNode(" ดาวน์โหลด "))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            (showDialogDownLoadFile.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[17] || (_cache[17] = _createElementVNode("p", { style: {"font-weight":"bold","font-size":"14px","margin-bottom":"20px"} }, " เลือกประเภทไฟล์ ", -1)),
                    _createVNode(_component_v_radio_group, {
                      id: "radio-fileType",
                      style: {"font-size":"14px"},
                      modelValue: selectedTypeFile.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedTypeFile).value = $event)),
                      column: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_radio, {
                          color: "#387469",
                          value: "excel"
                        }, {
                          label: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createElementVNode("span", { style: {"font-size":"14px","font-weight":"normal","color":"#000000"} }, "ไฟล์ XLSX", -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_radio, {
                          color: "#387469",
                          value: "csv"
                        }, {
                          label: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createElementVNode("span", { style: {"font-size":"14px","font-weight":"normal","color":"#000000"} }, "ไฟล์ CSV", -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_v_btn, {
                        variant: "outlined",
                        id: "btn-cancel-download-file",
                        onClick: closeDownloadDialog,
                        class: "fg-bold",
                        style: {"font-size":"12px","line-height":"16px","font-weight":"bold","height":"32px","width":"95px","color":"#1b5faa"}
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("ยกเลิก")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        variant: "tonal",
                        id: "btn-confirm-download-file",
                        onClick: downLoadFile,
                        disabled: !selectedTypeFile.value,
                        class: "fg-bold",
                        style: _normalizeStyle([{"font-size":"12px","line-height":"16px","font-weight":"bold","background-color":"#1b5faa","height":"32px","width":"95px","margin-left":"8px","color":"#ffffff"}, {
                      backgroundColor: selectedTypeFile.value ? '#1b5faa' : '#d7d8d9',
                      color: selectedTypeFile.value ? '#ffffff' : '#A2A4A7',
                      opacity: selectedTypeFile.value ? 1 : 0.8,
                    }])
                      }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode(" ตกลง")
                        ])),
                        _: 1
                      }, 8, ["disabled", "style"])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_v_data_table, {
            id: "table-upload-list",
            headers: filteredHeaders.value,
            items: filteredUploadList.value,
            class: "custom-table",
            loading: isLoading.value || isLoadingFillter.value,
            "items-per-page": options.value.itemsPerPage,
            "server-items-length": totalUploadList.value,
            options: options.value,
            "hide-default-footer": totalUploadList.value <= 0,
            onClick: clickTable
          }, {
            loading: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
            ]),
            "no-data": _withCtx(() => [
              (!isLoading.value && !isLoadingFillter.value && totalUploadList.value <= 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[18] || (_cache[18] = [
                    _createElementVNode("div", { style: {"text-align":"center","margin-top":"120px"} }, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        alt: "My Icon",
                        style: {"width":"120px","height":"120px"}
                      })
                    ], -1),
                    _createElementVNode("div", { style: {"text-align":"center","margin-top":"16px"} }, [
                      _createElementVNode("p", { style: {"font-size":"16px","line-height":"24px","color":"#4a4d51"} }, " ไม่มีรายการที่อัปโหลด ")
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            item: _withCtx(({ item }) => [
              _createElementVNode("tr", {
                class: _normalizeClass({ 'red-row': !item.isFileLasted })
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(item.projectName), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_19, _toDisplayString(item.fileMonthName), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString(item.fileYear), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_21, _toDisplayString(item.createDate), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_22, _toDisplayString(item.createBy), 1)
                ]),
                _createElementVNode("td", null, [
                  (!item.isFileLasted)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_23))
                    : _createCommentVNode("", true)
                ]),
                (userRole.value == 'Admin')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_24, [
                      _createVNode(_component_v_hover, null, {
                        default: _withCtx(({ isHovering, props }) => [
                          _createElementVNode("a", _mergeProps({ style: {"width":"32px","height":"32px","display":"flex","justify-content":"center","align-items":"center","cursor":"pointer"} }, props), [
                            _createElementVNode("img", {
                              src: 
                          isHovering
                            ? require('@/assets/icons/ic_hover_csv.svg')
                            : require('@/assets/icons/ic_CSV_file.svg')
                        ,
                              alt: "File Icon",
                              width: isHovering ? 32 : 20,
                              height: isHovering ? 32 : 20,
                              onClick: _withModifiers(($event: any) => (downloadFileTable(item.fileCSVLink)), ["stop"])
                            }, null, 8, _hoisted_25)
                          ], 16)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("td", null, [
                  _createVNode(_component_v_hover, null, {
                    default: _withCtx(({ isHovering, props }) => [
                      _createElementVNode("a", _mergeProps({ style: {"width":"32px","height":"32px","display":"flex","justify-content":"center","align-items":"center","cursor":"pointer"} }, props), [
                        _createElementVNode("img", {
                          src: 
                          isHovering
                            ? require('@/assets/icons/ic_hover_xls.svg')
                            : require('@/assets/icons/ic_xls_file.svg')
                        ,
                          alt: "File Icon",
                          width: isHovering ? 32 : 20,
                          height: isHovering ? 32 : 20,
                          onClick: _withModifiers(($event: any) => (downloadFileTable(item.fileExcelLink)), ["stop"])
                        }, null, 8, _hoisted_26)
                      ], 16)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ], 2)
            ]),
            _: 1
          }, 8, ["headers", "items", "loading", "items-per-page", "server-items-length", "options", "hide-default-footer"])
        ])
      ]),
      _createVNode(_component_v_overlay, {
        "model-value": isDownloadFile.value && isClickDownLoadFile.value,
        persistent: "",
        class: "align-center justify-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_progress_circular, {
            color: "primary",
            size: "64",
            indeterminate: ""
          })
        ]),
        _: 1
      }, 8, ["model-value"]),
      (showUploadDialog.value)
        ? (_openBlock(), _createBlock(UploadDialog, {
            key: 0,
            id: "upload-dialog",
            modelValue: showUploadDialog.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showUploadDialog).value = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "footer" }, " UploadWorkShcedule System V 1.0 | Copyright © 2024 Sansiri PLC. ", -1))
  ]))
}
}

})