import { defineStore } from 'pinia';

export const useOverlayMainLayoutStore = defineStore('overlayMainLayout', {
  state: () => ({
    overlay: false,
  }),
  actions: {
    turnOnOverlay() {
      this.overlay = true;
    },
    turnOffOverlay() {
      this.overlay = false;
    },
  },
});
