import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import UploadFileList from '../views/UploadFileList.vue';
import { checkSession } from '../auth/msalInstance';
import Login from '../views/Login.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: UploadFileList,
    meta: { layout: MainLayout, requiresAuth: true },
  },
  {
    path: '/WorkShcedule-Main',
    name: 'WorkShcedule-Main',
    component: UploadFileList,
    meta: { layout: MainLayout, requiresAuth: true },
  },
  {
    path: '/report',
    name: 'report',
    component: UploadFileList,
    meta: { layout: MainLayout, requiresAuth: true },
  },
  {
    path: '/setting',
    name: 'setting',
    component: UploadFileList,
    meta: { layout: MainLayout, requiresAuth: true },
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { layout: LoginLayout, requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const userEmail = sessionStorage.getItem('userEmail');
  const isAuthenticated = checkSession(userEmail);
  const accessToken = sessionStorage.getItem('accessToken');

  if (window.location.hash) {
    next();
    return;
  }

  if (to.path === '/login' && accessToken && isAuthenticated) {
    next('/');
  } else if ((!accessToken || !isAuthenticated) && requiresAuth) {
    next('/login');
  } else {
    next();
  }
});
export default router;
