import { defineStore } from 'pinia';
import axios from 'axios';
import { PeriodResponse } from '@/models/SetUpResopnse';
import config from '../../config';
import { StatusCodes as HttpStatus } from 'http-status-codes';

export const useUpLoadSetUpStore = defineStore('uploadSetUp', {
  state: () => ({
    periodDetail: null as PeriodResponse | null,
    status: null as Number | null,
    isLoading: true,
    error: null as string | null,
  }),
  actions: {
    async fetchUpLoadSetUpDetail() {
      this.isLoading = true;
      this.error = null;
      const empCode = sessionStorage.getItem('empCode') || '';
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.get(
          `${(envConfig.UWSApi as any).host}/api/Setup/${empCode}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        this.periodDetail = response?.data?.data || null;
        this.status = response?.data?.status || response?.status || null;
      } catch (error: any) {
        this.status = error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
        console.log('  this.periodDetail ::>', this.periodDetail);
      }
    },
  },
});
