import {
  msalInstance,
  checkSession,
  initializeMsal,
} from '../auth/msalInstance';
import { useRouter } from 'vue-router';
import { useEmployeeDetailStore } from '@/stores/EmployeeDetail';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import { useEmployeeRoleDetailStore } from '@/stores/EmployeeRole';

export const login = async () => {
  const loginRequest = {
    scopes: ['User.Read'],
  };
  try {
    await initializeMsal();
    sessionStorage.removeItem('msal.interaction.status');
    const loginResponse = await msalInstance.loginPopup(loginRequest);
    const accessToken = loginResponse.accessToken;
    const userName = loginResponse?.account?.name || '';
    const userEmail = loginResponse?.account?.username || '';
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('userName', userName);
    sessionStorage.setItem('userEmail', userEmail);

    const payload: EmployeeDetailPayload = {
      email: userEmail ? [userEmail] : [''],
      empWorkStatus: '',
      empName: '',
      empLastName: '',
      companyCode: '',
      divisionCode: null,
      departmentCode: null,
      empGroupCode: '',
      pageNumber: 1,
    };

    const employeeDetailStore = useEmployeeDetailStore();
    const employeeRoleStore = useEmployeeRoleDetailStore();
    await employeeDetailStore.fetchEmployeeDetail(payload);
    const empDetail = employeeDetailStore.empDetail;
    const empCode = empDetail?.empCode || '';
    sessionStorage.setItem('empCode', empCode);
    await employeeRoleStore.fetchEmployeeRoleDetail(empCode);
    const userRole = employeeRoleStore?.userRole || '';
    const directOrgTH = employeeRoleStore?.detailRole?.directOrgTH || '';
    sessionStorage.setItem('userRole', userRole);
    sessionStorage.setItem('directOrgTH', directOrgTH);
    if (!empCode) {
      alert('คุณไม่มีสิทธิ์ในการเข้าถึงระบบ');
      logout();
    }
    return loginResponse;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    await initializeMsal();
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('empCode');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('directOrgTH');

    if (msalInstance.getAllAccounts().length > 0) {
      await msalInstance.logoutRedirect();
      console.log('Logout initiated');
    } else {
      console.log('No active session found');
    }
  } catch (error) {
    console.error('Logout failed:', error);
  }
};
