import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/sansiri-logo-sign.svg'
import _imports_1 from '@/assets/icons/toggle_nav.svg'
import _imports_2 from '@/assets/icons/ic_logout.svg'


const _hoisted_1 = { class: "flex-container" }
const _hoisted_2 = { class: "user-info-container" }
const _hoisted_3 = {
  key: 0,
  class: "user-details",
  style: {}
}
const _hoisted_4 = {
  id: "user-name",
  style: {"margin":"0","font-size":"14px","line-height":"20px"}
}
const _hoisted_5 = {
  id: "direct-org-th",
  style: {"margin":"0","font-size":"14px","line-height":"20px"}
}
const _hoisted_6 = {
  id: "user-email",
  style: {"margin":"0","font-size":"12px","color":"#1b5faa","line-height":"16px"}
}
const _hoisted_7 = { class: "icon-toggle" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "nav-list" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { style: {"padding":"16px"} }

import IconUpload from '@/assets/icons/ic_doc_attached.svg';
import IconReport from '@/assets/icons/ic_doc_report.svg';
import IconSettings from '@/assets/icons/ic_setting_line.svg';
import { logout } from '@/auth/authService';
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useEmployeeDetailStore } from '@/stores/EmployeeDetail';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import { EmployeeDetailResponse } from '@/models/EmployeeDetailResponse';
import { useOverlayMainLayoutStore } from '@/stores/OverlayMainLayout';
import { useEmployeeRoleDetailStore } from '@/stores/EmployeeRole';
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useProjecOrganizationStore } from '@/stores/ProjecOrganization';
import config from '../../config';
import adminIcon from '@/assets/icons/ic_employee_circle_admin.svg';
import userIcon from '@/assets/icons/ic_employee_circle_user.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const envConfig = config();
const menuIdUploadFileList = (envConfig.SelfHost.menuId as any).uploadFileList;
const menuIdReport = (envConfig.SelfHost.menuId as any).report;
const menuIdSetting = (envConfig.SelfHost.menuId as any).setting;

const employeeDetailStore = useEmployeeDetailStore();
const employeeRoleStore = useEmployeeRoleDetailStore();
const overlayMainLayout = useOverlayMainLayoutStore();

const storeUploadSetUp = useUpLoadSetUpStore();
const storeProjecOrganization = useProjecOrganizationStore();

const isLoadingUploadDialog = computed(
  () => storeUploadSetUp.isLoading || storeProjecOrganization.isLoading,
);

const isOverlayMainLayout = computed(() => overlayMainLayout.overlay);

const isLoading = computed(
  () => employeeDetailStore.isLoading || employeeRoleStore.isLoading,
);
const error = computed(
  () => employeeDetailStore.error || employeeRoleStore.error,
);

// State variables
const drawer = ref(true);
const rail = ref(false);
const selectedItem = ref<number | null>(null);

const userRole = ref(sessionStorage.getItem('userRole'));
const userName = ref(sessionStorage.getItem('userName'));
const userEmail = ref(sessionStorage.getItem('userEmail'));
const directOrgTH = ref(sessionStorage.getItem('directOrgTH'));
const navItems = ref<{ title: string; icon: string; route: string | '' }[]>([]);
const route = useRouter();

const getRoleIcon = computed(() => {
  return userRole.value === 'Admin' ? adminIcon : userIcon;
});
// Methods
const selectItem: any = (index: number) => {
  selectedItem.value = index;
};

const toggleRail = () => {
  rail.value = !rail.value;
};

const handleLogout = async () => {
  await logout();
};

const getIconForMenu = (menuID: string) => {
  switch (menuID) {
    case menuIdUploadFileList:
      return IconUpload;
    case menuIdReport:
      return IconReport;
    case menuIdSetting:
      return IconSettings;
    default:
      return IconUpload;
  }
};

const getRouteForMenu = (menuID: string) => {
  switch (menuID) {
    case menuIdUploadFileList:
      return '/WorkShcedule-Main';
    case menuIdReport:
      return '/report';
    case menuIdSetting:
      return '/setting';
    default:
      return;
  }
};

const fetchDetails = async () => {
  // Create the payload
  const payload: EmployeeDetailPayload = {
    email: userEmail.value ? [userEmail.value] : [''],
    empWorkStatus: '',
    empName: '',
    empLastName: '',
    companyCode: '',
    divisionCode: null,
    departmentCode: null,
    empGroupCode: '',
    pageNumber: 1,
  };

  try {
    await employeeDetailStore.fetchEmployeeDetail(payload);
    const empDetail = employeeDetailStore.empDetail;
    const empCode = empDetail?.empCode || '';
    if (empCode) {
      await employeeRoleStore.fetchEmployeeRoleDetail(empCode);
      let menuData = employeeRoleStore.detailRole?.menu || [];
      const menuSort = ['รายการอัปโหลด', 'รายงาน', 'จัดการผู้ใช้งานระบบ'];
      const sortedMenuData = menuSort
        .map((menuName) => {
          return menuData.find((item) => item.menuName === menuName);
        })
        .filter((item) => item !== undefined);

      navItems.value = sortedMenuData.map((menu) => ({
        title: menu.menuName,
        icon: getIconForMenu(menu.menuID) || '',
        route: getRouteForMenu(menu.menuID) || '',
      }));
    } else {
      console.log('Employee code is missing.');
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('FINALLY_LOADING...', isLoading.value);
  }
};
onMounted(fetchDetails);

const currentRouteName = computed<string>(
  () => route.currentRoute.value.name as string,
);
const updateSelectedItem = (routePath: any) => {
  let findSelectedIndex = -1;
  if (routePath.value == 'home') {
    findSelectedIndex = 0;
  } else if (routePath.value == 'WorkShcedule-Main') {
    findSelectedIndex = 0;
  } else if (routePath.value == 'report') {
    findSelectedIndex = 1;
  } else if (routePath.value == 'setting') {
    findSelectedIndex = 2;
  }
  if (findSelectedIndex !== -1) {
    selectedItem.value = findSelectedIndex;
  }
};

onMounted(() => {
  updateSelectedItem(currentRouteName);
});

watch(
  () => route.currentRoute.value.name,
  (newPath) => {
    updateSelectedItem(newPath);
  },
);

return (_ctx: any,_cache: any) => {
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (!isLoading.value)
    ? (_openBlock(), _createBlock(_component_v_app, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_overlay, {
            "model-value": isOverlayMainLayout.value && !isLoadingUploadDialog.value,
            contained: "",
            class: "custom-overlay",
            style: {"z-index":"1040"},
            persistent: ""
          }, null, 8, ["model-value"]),
          _createVNode(_component_v_navigation_drawer, {
            app: "",
            modelValue: drawer.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((drawer).value = $event)),
            rail: rail.value,
            class: "drawer-container",
            permanent: "",
            style: {"background-color":"#efeff0"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_list_item, { style: {"background-color":"#162e46","border":"#0c1d2f 1px solid","height":"52px","padding":"8px"} }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("div", { class: "nav-header" }, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          style: {"padding-right":"12px"}
                        }),
                        _createElementVNode("div", { class: "nav-header-text" }, [
                          _createElementVNode("p", {
                            class: "fg-bold",
                            style: {"font-size":"16px","color":"#ffffff","font-weight":"bold"}
                          }, [
                            _createTextVNode(" Upload"),
                            _createElementVNode("span", {
                              class: "fg-normal",
                              style: {"font-weight":"normal","font-size":"16px","color":"#ffffff"}
                            }, "WorkShcedule")
                          ])
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item, { style: {"background-color":"#efeff0","padding":"16px"} }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (!rail.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createElementVNode("p", _hoisted_4, _toDisplayString(userName.value), 1),
                              _createElementVNode("p", _hoisted_5, _toDisplayString(directOrgTH.value), 1),
                              _createElementVNode("p", _hoisted_6, _toDisplayString(userEmail.value), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("img", {
                            src: _imports_1,
                            id: "toggle-nav",
                            alt: "Toggle Navigation",
                            onClick: _withModifiers(toggleRail, ["stop"]),
                            class: _normalizeClass({ 'rotate-icon': rail.value })
                          }, null, 2)
                        ])
                      ]),
                      (!rail.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            id: "role-name",
                            class: _normalizeClass({
                'role-container-admin': userRole.value === 'Admin',
                'role-container-uploader': userRole.value === 'Uploader',
              })
                          }, [
                            _createElementVNode("img", {
                              src: getRoleIcon.value,
                              id: "role-icon"
                            }, null, 8, _hoisted_8),
                            _createTextVNode(" " + _toDisplayString(userRole.value), 1)
                          ], 2))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { style: {"margin-bottom":"16px"} }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItems.value, (item, index) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      id: "nav-list-item",
                      key: index,
                      class: _normalizeClass({ 'selected-item': selectedItem.value === index }),
                      onClick: ($event: any) => (selectItem(index)),
                      link: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          class: "routerLink",
                          to: item.route
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("img", {
                                src: item.icon,
                                width: "20",
                                height: "20"
                              }, null, 8, _hoisted_10),
                              (!rail.value)
                                ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                    key: 0,
                                    class: _normalizeClass([
                    'nav-list-text',
                    { 'nav-list-text-bold': selectedItem.value === index },
                  ])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.title), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1032, ["class", "onClick"]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  (!rail.value)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        id: "btn-logout",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleLogout())),
                        variant: "outlined",
                        style: {"font-size":"12px","font-weight":"bold","margin-right":"16px","color":"#1b5faa","width":"100%"}
                      }, {
                        prepend: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("img", {
                            src: _imports_2,
                            alt: "My Icon",
                            width: "24",
                            height: "24"
                          }, null, -1)
                        ])),
                        default: _withCtx(() => [
                          _cache[5] || (_cache[5] = _createTextVNode(" ออกจากระบบ "))
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        variant: "text",
                        id: "btn-logout-rail",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (handleLogout())),
                        icon: "",
                        style: {"width":"48px","height":"48px","justify-content":"center"}
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("img", {
                            src: _imports_2,
                            alt: "Logout Icon",
                            width: "24",
                            height: "24"
                          }, null, -1)
                        ])),
                        _: 1
                      }))
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "rail"]),
          _createVNode(_component_v_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})