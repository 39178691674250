<template>
  <div class="login-bg">
    <v-row no-gutters fill-height style="height: 100vh; margin: 0">
      <v-col cols="4">
        <img
          src="@/assets/images/pattern-group.png"
          style="height: 100vh; width: 450px"
        />
      </v-col>
      <v-col class="col-contrainer">
        <v-row class="d-flex justify-center align-center" style="height: 100%">
          <v-col
            class="d-flex flex-column align-center"
            style="margin-bottom: 48px"
          >
            <img
              style="width: 220px; height: 30px; margin-bottom: 60px"
              src="@/assets/icons/sansiri-logo.svg"
            />
            <div class="d-flex flex-column align-center">
              <div class="div-contrainer">
                <div class="text-start" style="margin-bottom: 120px">
                  <img src="@/assets/icons/ic_upload.svg" />
                  <p>
                    <span class="title-text-bold">Upload</span>
                    <span class="title-text">WorkSchedule</span>
                  </p>

                  <p class="sub-text">ระบบอัปโหลดตารางการทำงาน</p>
                  <p class="des-text"></p>
                  <v-btn
                    @click="handleLogin()"
                    variant="flat"
                    width="100%"
                    height="52"
                    color="#1B5FAA"
                    style="
                      margin-top: 48px;
                      font-weight: bold;
                      font-size: 14px;
                      border-radius: 8px;
                    "
                    id="btn-login"
                  >
                    เข้าสู่ระบบ
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { login, logout } from '@/auth/authService';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { msalInstance } from '@/auth/msalInstance';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const router = useRouter();
const handleLogin = async () => {
  try {
    const loginResponse = await login(); // Call the login function from authService
    console.log('Login successful:', loginResponse);
    router.push('/');
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.log('Interaction is required to complete the login.');
    } else {
      console.error('Login failed:', error);
    }
  }
};
</script>
<style scoped>
@media (max-width: 400px) {
  .col-contrainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20%;
  }

  .div-contrainer {
    width: 480px !important;
    height: 332px !important;
    background-color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  .login-bg {
    background-color: #162e46;
    height: 100vh;
  }
}

.col-contrainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-contrainer {
  width: 544px;
  height: 332px;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 4px;
}

.login-bg {
  background-color: #162e46;
  height: 100vh; /* Full viewport height */
}

.form-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 475px;
}

.logo {
  text-align: center;
  margin-top: 20%;
  width: 176px;
  height: 24px;
}

.title-text-bold {
  font-size: 36px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 10px;
  color: #1b5faa;
  margin-top: 16px;
}

.title-text {
  font-size: 36px;
  font-weight: normal;
  margin-bottom: 10px;
  color: #1b5faa;
  margin-top: 16px;
}

.sub-text {
  font-size: 20px;
  margin-bottom: 10px;
  color: black;
  margin-top: 16px;
}

.des-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  color: #4a4d51;
  margin-top: 16px;
}

.text-capitalize {
  text-transform: none;
}
</style>
