import { defineStore } from 'pinia';
import axios from 'axios';
import { UploadFileResponse } from '@/models/UploadFileResponse';
import { UploadFilePayload } from '@/models/UploadFilePayload';
import config from '../../config';
import moment from 'moment';
import { StatusCodes as HttpStatus } from 'http-status-codes';

export const useUpLoadFileStore = defineStore('uploadFile', {
  state: () => ({
    uploadFile: null as UploadFileResponse | null,
    status: null as Number | null,
    message: '' as string,
    isLoading: true,
    isClick: false,
    error: null as string | null,
  }),
  actions: {
    async fetchUpLoadFileDetail(payload: UploadFilePayload) {
      this.isLoading = true;
      this.error = null;

      const formData = new FormData();
      formData.append('projectCode', payload.projectCode || '');
      formData.append('fileMonth', payload.fileMonth?.toString() || '');
      formData.append('fileYear', payload.fileYear?.toString() || '');
      formData.append('createBy', payload.createBy);
      if (payload.file) {
        formData.append('file', payload.file); // ส่งไฟล์
      }

      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.post(
          `${(envConfig.UWSApi as any).host}/api/FileUpload `,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        this.uploadFile = response?.data?.data || null;
        this.status = response?.data?.status || response?.status || null;
        this.message = response?.data?.message || null;
      } catch (error: any) {
        this.status = error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
      }
    },
  },
});
