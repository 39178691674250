<template v-if="!isLoading">
  <div style="display: flex; flex-direction: column; min-height: 100vh">
    <div style="">
      <NotiAppBar></NotiAppBar>
    </div>
    <div style="display: flex; flex-direction: column; min-height: 100vh">
      <div>
        <div style="display: flex; justify-content: space-between">
          <div class="layout-text">
            <p
              class="fg-bold"
              style="font-size: 28px; font-weight: bold; line-height: 40px"
            >
              ยินดีต้อนรับสู่ระบบ Upload Work Schedule
            </p>
            <p style="font-size: 16px; font-weight: normal; line-height: 24px">
              ระบบอัปโหลดตารางการทำงาน
            </p>
          </div>
          <div>
            <img src="@/assets/images/Artwork.png" alt="My Icon" />
          </div>
        </div>

        <div style="border-bottom: 1px solid; color: #c0c0c0"></div>

        <div style="padding: 16px 20px">
          <div class="upload-header-container">
            <p
              class="fg-bold"
              style="font-size: 20px; font-weight: bold; line-height: 32px"
            >
              รายการอัปโหลดตารางการทำงาน
            </p>

            <div>
              <v-btn
                variant="outlined"
                id="btn-download-template"
                href="/template/Template-UploadWork.xlsx"
                download
                @click="downloadTemplate"
                class="fg-bold"
                style="
                  font-size: 14px;
                  font-weight: bold;
                  margin-right: 8px;
                  color: #162e46;
                  line-height: 20px;
                  letter-spacing: normal;
                  word-spacing: normal;
                  width: 182px;
                "
              >
                <template v-slot:prepend>
                  <img
                    src="@/assets/icons/ic_btn_xlsx_download_file.svg"
                    alt="My Icon"
                    width="24"
                    height="24"
                  />
                </template>
                ดาวน์โหลดเทมเพลต
              </v-btn>

              <v-btn
                variant="tonal"
                class="custom-btn-disable fg-bold"
                id="btn-upload-file"
                :disabled="!uploadble"
                @click="openUploadDialog"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: bold;
                  background-color: #1b5faa;
                  color: #ffffff;
                  width: 111px;
                  letter-spacing: normal;
                  word-spacing: normal;
                "
                :style="{
                  backgroundColor: uploadble ? '#1b5faa' : '#d7d8d9',
                  color: uploadble ? '#ffffff' : '#A2A4A7',
                  opacity: uploadble ? 1 : 0.8,
                }"
              >
                <template v-slot:prepend>
                  <img
                    :src="
                      uploadble
                        ? require('@/assets/icons/ic_upload_white.svg')
                        : require('@/assets/icons/ic_upload_disable.svg')
                    "
                    alt="My Icon"
                    width="24"
                    height="24"
                    color="#A2A4A7"
                  />
                </template>
                อัปโหลด
              </v-btn>
            </div>
          </div>
        </div>
        <div class="header-warning">
          <div
            style="
              margin-left: 16px;
              width: 24px;
              height: 24px;
              align-items: center;
            "
          >
            <img
              src="@/assets/icons/ic_info_solid.svg"
              width="24"
              height="24"
            />
          </div>
          <div style="margin-left: 8px">
            <p style="font-size: 14px; line-height: 20px">
              สามารถอัปโหลดไฟล์ได้ในวันที่ 1-5 เท่านั้น
            </p>
          </div>
        </div>
        <div class="layout-main-search-table">
          <div class="upload-header-container">
            <div>
              <p
                class="fg-bold"
                style="font-size: 16px; font-weight: bold; line-height: 24px"
              >
                จำนวนรายการ :
                <span
                  class="fg-normal"
                  id="total-upload-list-show"
                  style="
                    font-size: 16px;
                    font-weight: normal;
                    color: #1b5faa;
                    line-height: 24px;
                  "
                  >{{ totalUploadList }}</span
                >
              </p>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-text-field
                v-model="searchProject"
                id="search-project"
                placeholder="ค้นหาจากชื่อโครงการ"
                variant="outlined"
                density="compact"
                style="width: 360px; height: 40px; font-size: 10px"
                class="custom-text-field"
                @click="clickSearch"
              >
                <template #prepend-inner>
                  <div style="width: 20px; height: 20px">
                    <img
                      style="align-items: center"
                      height="20"
                      width="20"
                      src="@/assets/icons/ic_search.svg"
                    />
                  </div>
                </template>
              </v-text-field>
              <Datepicker
                id="search-period"
                v-model="date"
                :clearable="false"
                :format-locale="thLocale"
                language="th"
                format="MMMM yyyy"
                month-picker
                style="width: 167px; margin-left: 8px"
                @open="clickDateTimeSelect"
                @update:model-value="onDateChange"
              >
              </Datepicker>

              <v-btn
                v-if="userRole == 'Admin'"
                variant="tonal"
                id="btn-download-file"
                @click="showDialogDownLoadFile = true"
                class="fg-bold"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: normal;
                  word-spacing: normal;
                  font-weight: bold;
                  background-color: #1b5faa;
                  height: 40px;
                  width: 126px;
                  margin-left: 8px;
                  color: #ffffff;
                "
              >
                <template v-slot:prepend>
                  <img
                    src="@/assets/icons/ic_download.svg"
                    alt="My Icon"
                    width="24"
                    height="24"
                  />
                </template>
                ดาวน์โหลด
              </v-btn>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <!-- Dialog 2 -->
            <div
              v-if="showDialogDownLoadFile"
              style="
                z-index: 9999;
                position: absolute;
                top: 300px;
                background-color: #fff;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                width: 230px;
              "
            >
              <div style="margin: 16px">
                <p
                  style="
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 20px;
                  "
                >
                  เลือกประเภทไฟล์
                </p>
                <v-radio-group
                  id="radio-fileType"
                  style="font-size: 14px"
                  v-model="selectedTypeFile"
                  column
                >
                  <v-radio color="#387469" value="excel">
                    <template v-slot:label>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: normal;
                          color: #000000;
                        "
                        >ไฟล์ XLSX</span
                      >
                    </template></v-radio
                  >
                  <v-radio color="#387469" value="csv">
                    <template v-slot:label>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: normal;
                          color: #000000;
                        "
                        >ไฟล์ CSV</span
                      >
                    </template>
                  </v-radio>
                </v-radio-group>

                <div style="display: flex; justify-content: flex-end">
                  <v-btn
                    variant="outlined"
                    id="btn-cancel-download-file"
                    @click="closeDownloadDialog"
                    class="fg-bold"
                    style="
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: bold;
                      height: 32px;
                      width: 95px;
                      color: #1b5faa;
                      font-weight: bold;
                    "
                    >ยกเลิก</v-btn
                  >
                  <v-btn
                    variant="tonal"
                    id="btn-confirm-download-file"
                    @click="downLoadFile"
                    :disabled="!selectedTypeFile"
                    class="fg-bold"
                    style="
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: bold;
                      background-color: #1b5faa;
                      height: 32px;
                      width: 95px;
                      margin-left: 8px;
                      color: #ffffff;
                      font-weight: bold;
                    "
                    :style="{
                      backgroundColor: selectedTypeFile ? '#1b5faa' : '#d7d8d9',
                      color: selectedTypeFile ? '#ffffff' : '#A2A4A7',
                      opacity: selectedTypeFile ? 1 : 0.8,
                    }"
                  >
                    ตกลง</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-work-schedule">
          <v-data-table
            id="table-upload-list"
            :headers="filteredHeaders"
            :items="filteredUploadList"
            class="custom-table"
            :loading="isLoading || isLoadingFillter"
            :items-per-page="options.itemsPerPage"
            :server-items-length="totalUploadList"
            :options.sync="options"
            :hide-default-footer="totalUploadList <= 0"
            @click="clickTable"
          >
            <!-- Loading state -->
            <template v-slot:loading>
              <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>

            <!-- No data state -->
            <template v-slot:no-data>
              <div
                v-if="!isLoading && !isLoadingFillter && totalUploadList <= 0"
                class="no-data-message"
              >
                <div style="text-align: center; margin-top: 120px">
                  <img
                    src="@/assets/icons/illus_empty_file.svg"
                    alt="My Icon"
                    style="width: 120px; height: 120px"
                  />
                </div>
                <div style="text-align: center; margin-top: 16px">
                  <p style="font-size: 16px; line-height: 24px; color: #4a4d51">
                    ไม่มีรายการที่อัปโหลด
                  </p>
                </div>
              </div>
            </template>

            <template v-slot:item="{ item }">
              <tr :class="{ 'red-row': !item.isFileLasted }">
                <td>
                  <p style="margin-left: 16px">{{ item.projectName }}</p>
                </td>
                <td>
                  <p class="detail-table">
                    {{ item.fileMonthName }}
                  </p>
                </td>
                <td>
                  <p class="detail-table">
                    {{ item.fileYear }}
                  </p>
                </td>
                <td>
                  <p class="detail-table">
                    {{ item.createDate }}
                  </p>
                </td>
                <td>
                  <p class="detail-table">
                    {{ item.createBy }}
                  </p>
                </td>
                <td>
                  <img
                    src="@/assets/icons/ic_history.svg"
                    alt="My Icon"
                    width="20"
                    height="20"
                    v-if="!item.isFileLasted"
                  />
                </td>
                <td v-if="userRole == 'Admin'">
                  <v-hover v-slot:default="{ isHovering, props }">
                    <a
                      style="
                        width: 32px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                      "
                      v-bind="props"
                    >
                      <img
                        :src="
                          isHovering
                            ? require('@/assets/icons/ic_hover_csv.svg')
                            : require('@/assets/icons/ic_CSV_file.svg')
                        "
                        alt="File Icon"
                        :width="isHovering ? 32 : 20"
                        :height="isHovering ? 32 : 20"
                        @click.stop="downloadFileTable(item.fileCSVLink)"
                      />
                    </a>
                  </v-hover>
                </td>
                <td>
                  <v-hover v-slot:default="{ isHovering, props }">
                    <a
                      style="
                        width: 32px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                      "
                      v-bind="props"
                    >
                      <img
                        :src="
                          isHovering
                            ? require('@/assets/icons/ic_hover_xls.svg')
                            : require('@/assets/icons/ic_xls_file.svg')
                        "
                        alt="File Icon"
                        :width="isHovering ? 32 : 20"
                        :height="isHovering ? 32 : 20"
                        @click.stop="downloadFileTable(item.fileExcelLink)"
                      />
                    </a>
                  </v-hover>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>

      <v-overlay
        :model-value="isDownloadFile && isClickDownLoadFile"
        persistent
        class="align-center justify-center"
      >
        <v-progress-circular
          color="primary"
          size="64"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <UploadDialog
        id="upload-dialog"
        v-model="showUploadDialog"
        v-if="showUploadDialog"
      ></UploadDialog>
    </div>
    <div class="footer">
      UploadWorkShcedule System V 1.0 | Copyright © 2024 Sansiri PLC.
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, onBeforeMount, nextTick } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { th } from 'date-fns/locale';
import { useUpLoadListStore } from '@/stores/GetUploadList';
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useReloadTableUploadStore } from '@/stores/ReloadUploadList';
import { UploadList } from '@/models/UploadListResopnse';
import { UploadListPayload } from '@/models/UploadListPlayload';
import { DownloadFilePayload } from '@/models/DownloadFilePayload';
import UploadDialog from '@/components/UploadDialog.vue';
import NotiAppBar from '@/components/NotiAppBar.vue';
import { useDownloadFileStore } from '@/stores/DownloadFile';
import { useEmployeeRoleDetailStore } from '@/stores/EmployeeRole';
import { useEmployeeDetailStore } from '@/stores/EmployeeDetail';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import { useUpLoadFileStore } from '@/stores/UploadFile';

const currentDate = new Date();
//  const setupUpload = storeUploadSetUp.periodDetail;
// uploadble.value = setupUpload?.period?.canUpload || false;

const monthMapNumber: { [key: number]: string } = {
  1: 'มกราคม',
  2: 'กุมภาพันธ์',
  3: 'มีนาคม',
  4: 'เมษายน',
  5: 'พฤษภาคม',
  6: 'มิถุนายน',
  7: 'กรกฎาคม',
  8: 'สิงหาคม',
  9: 'กันยายน',
  10: 'ตุลาคม',
  11: 'พฤศจิกายน',
  12: 'ธันวาคม',
};

const showDialogDownLoadFile = ref(false);
const selectedTypeFile = ref('');
const thLocale: any = th;
const uploadListData = ref<UploadList[]>([]);
const searchProject = ref('');
const hasFetchedData = ref(false);
const uploadble = ref(false);
const storeUploadList = useUpLoadListStore();
const storeUploadSetUp = useUpLoadSetUpStore();
const employeeRoleStore = useEmployeeRoleDetailStore();
const downloadFileStore = useDownloadFileStore();
const storeUpLoadFile = useUpLoadFileStore();
const storeReloadTableUpload = useReloadTableUploadStore();
const isClickDownLoadFile = ref(false);
const isLoadingFillter = ref(true);
const showUploadDialog = ref(false);
const userRole = ref(sessionStorage.getItem('userRole'));
const employeeDetailStore = useEmployeeDetailStore();
const userEmail = ref(sessionStorage.getItem('userEmail'));
const date = ref({
  month: 0, // ค่าเริ่มต้นเป็น 0
  year: 0, // ค่าเริ่มต้นเป็น 0
});
const payload: EmployeeDetailPayload = {
  email: userEmail.value ? [userEmail.value] : [''],
  empWorkStatus: '',
  empName: '',
  empLastName: '',
  companyCode: '',
  divisionCode: null,
  departmentCode: null,
  empGroupCode: '',
  pageNumber: 1,
};

onMounted(async () => {
  if (
    !hasFetchedData.value ||
    (storeUpLoadFile.isClick == true && storeUpLoadFile.isLoading == false)
  ) {
    await storeUploadSetUp.fetchUpLoadSetUpDetail();
    const currentPeriod = storeUploadSetUp.periodDetail;
    uploadble.value = currentPeriod?.period?.canUpload || false; // uploadble.value = true;
    date.value = {
      month: Number(currentPeriod?.period?.currentPeriodMonth ?? 0) - 1,
      year: Number(currentPeriod?.period?.currentPeriodYear ?? 0),
    };
    const empCode = sessionStorage.getItem('empCode') || '';
    if (empCode) {
      await fetchDetails(empCode);
    }
  }
});

/*onBeforeMount(async () => {
  await storeUploadSetUp.fetchUpLoadSetUpDetail();
  const currentPeriod = storeUploadSetUp.periodDetail;
  uploadble.value = currentPeriod?.period?.canUpload || false; // uploadble.value = true;
  date.value = {
    month: Number(currentPeriod?.period?.currentPeriodMonth ?? 0) - 1,
    year: Number(currentPeriod?.period?.currentPeriodYear ?? 0),
  };
}); */

const isReloadUploadList = computed(() => storeReloadTableUpload.isReload);
watch(isReloadUploadList, async (newValue, oldValue) => {
  if (newValue === true) {
    hasFetchedData.value = false;
    const empCode = sessionStorage.getItem('empCode') || '';
    let UpLoadListDetailPayload: UploadListPayload = {
      empCode: empCode,
      fileMonth: date.value.month + 1,
      fileYear: date.value.year,
    };
    await storeUploadList.fetchUpLoadListDetail(UpLoadListDetailPayload);
    if (Array.isArray(storeUploadList?.uploadList)) {
      uploadListData.value = storeUploadList.uploadList;
    } else {
      uploadListData.value = [];
    }
    storeReloadTableUpload.ResetReload();
  }
});
const filteredUploadList = computed(() => {
  const selectedDate = date.value;
  const selectedMonth = selectedDate.month + 1;
  const selectedYear = selectedDate.year;

  const filteredList = uploadListData.value
    .filter((item) => {
      isLoadingFillter.value = true;
      const matchesProject =
        searchProject.value.trim() === '' ||
        item.projectName
          .toLowerCase()
          .includes(searchProject.value.toLowerCase());
      const matchesDate =
        item.fileMonth == selectedMonth && item.fileYear == selectedYear;
      isLoadingFillter.value = false;
      return matchesProject && matchesDate;
    })
    .map((item) => {
      return {
        ...item,
        fileMonthName: monthMapNumber[item.fileMonth],
      };
    });

  isLoadingFillter.value = false;
  return filteredList;
});

const filteredHeaders = computed(() => {
  if (userRole.value !== 'Admin') {
    return headers.value.filter((header) => header.value !== 'fileCSVLink');
  }
  return headers.value;
});
const onDateChange = async () => {
  hasFetchedData.value = false;
  const empCode = sessionStorage.getItem('empCode') || '';
  await fetchDetails(empCode);
};
const closeDownloadDialog = () => {
  if (showDialogDownLoadFile.value == true) {
    showDialogDownLoadFile.value = false;
  }
  selectedTypeFile.value = '';
};
const downloadTemplate = () => {
  closeDownloadDialog();
};
const openUploadDialog = () => {
  closeDownloadDialog();
  showUploadDialog.value = true;
};
const clickTable = () => {
  closeDownloadDialog();
};
const clickSearch = () => {
  closeDownloadDialog();
};
const clickDateTimeSelect = () => {
  closeDownloadDialog();
};

const totalUploadList = computed(() => {
  return filteredUploadList?.value?.length || 0;
});
const isLoading = computed(
  () =>
    storeUploadSetUp.isLoading ||
    storeUploadList.isLoading ||
    employeeDetailStore.isLoading ||
    employeeRoleStore.isLoading,
);

const isDownloadFile = computed(() => downloadFileStore.isLoading);
const error = computed(
  () =>
    storeUploadSetUp.error || storeUploadList.error || employeeRoleStore.error,
);

const fetchDetails = async (empCode: string) => {
  if (hasFetchedData.value) return;
  try {
    let UpLoadListDetailPayload: UploadListPayload = {
      empCode: empCode,
      fileMonth: date.value.month + 1,
      fileYear: date.value.year,
    };
    console.log('UpLoadListDetailPayload::>', UpLoadListDetailPayload);
    await storeUploadList.fetchUpLoadListDetail(UpLoadListDetailPayload);
    if (Array.isArray(storeUploadList?.uploadList)) {
      uploadListData.value = storeUploadList.uploadList;
    } else {
      uploadListData.value = [];
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('fetchDetails_LOADING...', isLoading.value);
    hasFetchedData.value = true;
  }
};

const options = ref({
  page: 1,
  itemsPerPage: 10,
  sortBy: ['createDateValue'],
  sortDesc: [true],
});

interface Header {
  title: string;
  value: string;
  key?: string;
  width?: string;
  align?: string;
  class?: string;
}

const headers = ref<Header[]>([
  {
    title: 'โครงการ',
    value: 'projectName',
    key: 'projectName',
    width: '25%',
    align: 'start',
  },
  { title: 'เดือน', value: 'fileMonthName', width: '9%', align: 'start' },
  { title: 'ปี', value: 'fileYear', width: '9%', align: 'start' },
  {
    title: 'วันที่อัปโหลด',
    value: 'createDate',
    key: 'createDate',
    width: '15%',
    align: 'start',
  },
  { title: 'ผู้อัปโหลด', value: 'createBy', width: '36%', align: 'start' },
  { title: '', value: 'isFileLasted', width: '2%', align: 'center' },
  { title: '', value: 'fileCSVLink', width: '2%', align: 'center' },
  { title: '', value: 'fileExcelLink', width: '2%', align: 'center' },
]);

let isDownloading = false;
async function downloadFileTable(fileLink: string | undefined) {
  if (!fileLink || isDownloading) return;

  isDownloading = true;
  try {
    closeDownloadDialog();
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = '';
    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed:', error);
  } finally {
    isDownloading = false;
  }
}

async function downLoadFile() {
  isClickDownLoadFile.value = true;
  let downloadFilePayload: DownloadFilePayload = {
    fileMonth: date.value.month + 1,
    fileYear: date.value.year,
    fileType: selectedTypeFile.value,
  };
  showDialogDownLoadFile.value = false;
  await downloadFileStore.fetchFileDownload(downloadFilePayload);
  isClickDownLoadFile.value = false;
  selectedTypeFile.value = '';
}

const dataUpload = ref([
  {
    projectName: 'สารุญสิริ บางใหญ่',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '11/11/2024 17:44',
    createBy: 'ไพฑูรย์ รุ่งฤดี',
    isFileLasted: true,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
  {
    projectName: 'สารุญสิริ บางนา',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '10/11/2024 17:44',
    createBy: 'อนันต์เป็ด สนุกดี',
    isFileLasted: false,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
]);
</script>
<style scoped>
.red-row {
  background-color: #efeff0 !important;
}
.layout-text {
  padding: 20px;
}
.custom-table {
  table-layout: fixed;
  width: 100%;
}

.layout-main-search-table {
  padding: 16px 20px 0;
}
.upload-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-warning {
  border-left: 2px #08327f solid;
  background-color: #e4ecf5;
  margin-left: 20px;
  margin-right: 20px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.custom-datepicker {
  width: 250px;
}

.table-work-schedule {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 16px;
}
.v-data-table >>> th {
  font-family: GraphikTH-SemiBold;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 16px !important;
  background-color: #162e46 !important;
  height: 40px !important;
  color: #ffffff;
}

::v-deep(.v-data-table th:hover) {
  color: #ffffff !important;
}

::v-deep(.v-data-table tr .is-file-lasted) {
  background-color: red !important;
}
.detail-table {
  text-align: start;
  margin-left: 16px;
}
.icon-change-enter,
.icon-change-leave-to {
  opacity: 0;
  transform: scale(0.8); /* Optional: Scale effect */
}
.dp__input_wrap >>> input {
  height: 45px !important;
}

.v-data-table >>> tbody td {
  height: 40px !important;
  color: #000000;
  line-height: 20px;
  padding: 0 !important;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
  height: 200px;
  text-align: center;
}

.v-progress-circular {
  margin: 1rem;
}
.footer {
  font-size: 12px;
  color: #4a4d51;
  margin-left: 16px;
  margin-bottom: 10px;
}
::v-deep(.dp__input_wrap .dp__input) {
  height: 40px;
  font-family: GraphikTH-Regular;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
}
::v-deep(.v-field) {
  font-family: GraphikTH-Regular;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
}

.v-btn.v-btn--disabled {
  background-color: #d7d8d9 !important; /* Normal state */
}
.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}
</style>
