import { defineStore } from 'pinia';
import axios from 'axios';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import config from '../../config';
import { EmployeeDetailResponse } from '@/models/EmployeeDetailResponse';
import { StatusCodes as HttpStatus } from 'http-status-codes';
export const useEmployeeDetailStore = defineStore('employeeDetail', {
  state: () => ({
    empDetail: null as EmployeeDetailResponse | null,
    status: null as Number | null,
    isLoading: true,
    error: null as string | null,
  }),
  actions: {
    async fetchEmployeeDetail(payload: EmployeeDetailPayload) {
      this.isLoading = true;
      this.error = '';
      const envConfig = config();
      try {
        const response = await axios.post(
          `${(envConfig.EmployeeDetail as any).host}/EmployeeDetail/GetEmpDetail`,
          payload,
          {
            headers: {
              Authorization: (envConfig.EmployeeDetail as any).token as string,
            },
          },
        );

        this.empDetail = response?.data?.respond_data[0]?.empDetail[0] || null;
        this.status = response?.data?.status || response?.status || null;
      } catch (error: any) {
        this.status = error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
      }
    },
  },
});
