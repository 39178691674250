import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/ic_check_circle_line.svg'


const _hoisted_1 = {
  key: 0,
  style: {"position":"fixed","width":"100%","top":"0","z-index":"9999"}
}
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"start"} }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: "My Icon",
  width: "21",
  height: "21",
  style: {"align-items":"center","margin-right":"8px","margin-left":"6px"}
}
const _hoisted_4 = { class: "message-noti" }

import { useNotificationAppBar } from '@/stores/NotiApp';

export default /*@__PURE__*/_defineComponent({
  __name: 'NotiAppBar',
  setup(__props) {

const storeNotificationAppBar = useNotificationAppBar();
//storeNotificationAppBar.status = 'SUCCESS';
//storeNotificationAppBar.color = '#387469';
//storeNotificationAppBar.messageText = 'อัปโหลดตารางการทำงานเรียบร้อยแล้ว';

return (_ctx: any,_cache: any) => {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_unref(storeNotificationAppBar).isHideNotification)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_alert, {
          rounded: "0",
          style: { width: '100%', padding: '10px', height: '44px' },
          color: _unref(storeNotificationAppBar).color
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_unref(storeNotificationAppBar).status == 'SUCCESS')
                ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(storeNotificationAppBar).messageText), 1)
            ])
          ]),
          _: 1
        }, 8, ["color"])
      ]))
    : _createCommentVNode("", true)
}
}

})