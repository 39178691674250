<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || 'div';
    });

    return {
      layout,
    };
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

@font-face {
  font-family: 'GraphikTH-Regular';
  src:
    url('@/assets/fonts/GraphikTH-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('@/assets/fonts/GraphikTH-Regular.woff') format('woff'),
    url('@/assets/fonts/GraphikTH-Regular.ttf') format('truetype'),
    url('@/assets/fonts/GraphikTH-Regular.svg#GraphikTH-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikTH-SemiBold';
  src:
    url('@/assets/fonts/GraphikTH-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('@/assets/fonts/GraphikTH-SemiBold.woff') format('woff'),
    url('@/assets/fonts/GraphikTH-SemiBold.ttf') format('truetype'),
    url('@/assets/fonts/GraphikTH-SemiBold.svg#GraphikTH-SemiBold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#app {
  font-family: GraphikTH-Regular;
}
p {
  color: #000000;
}
.fg-bold {
  font-family: GraphikTH-SemiBold;
  font-weight: bold;
}
.fg-normal {
  font-family: GraphikTH-Regular;
  font-weight: normal;
}
</style>
