import { defineStore } from 'pinia';
import axios from 'axios';
import { EmployeeRoleDetailResponse } from '@/models/EmployeeRoleResponse';
import config from '../../config';
import { StatusCodes as HttpStatus } from 'http-status-codes';

export const useEmployeeRoleDetailStore = defineStore('employeeRole', {
  state: () => ({
    detailRole: null as EmployeeRoleDetailResponse | null,
    userRole: null as string | null,
    status: null as Number | null,
    isLoading: true,
    error: null as string | null,
  }),
  actions: {
    async fetchEmployeeRoleDetail(payload: string) {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.get(
          `${(envConfig.UWSApi as any).host}/api/Authorize/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        this.detailRole = response?.data?.data || null;
        this.userRole = response?.data?.data?.roles[0]?.roleName || null;
        this.status = response?.data?.data?.status || response?.status || null;
      } catch (error: any) {
        this.status = this.status =
          error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
      }
    },
  },
});
