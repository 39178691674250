import { defineStore } from 'pinia';
import axios from 'axios';
import { DownloadFileResponse } from '@/models/DownloadFileResponse';
import { DownloadFilePayload } from '@/models/DownloadFilePayload';
import config from '../../config';
import moment from 'moment';
import { StatusCodes as HttpStatus } from 'http-status-codes';

export const useDownloadFileStore = defineStore('downloadFile', {
  state: () => ({
    downloadFile: null as Uint8Array | null,
    status: null as Number | null,
    isLoading: true,
    error: null as string | null,
  }),
  actions: {
    async fetchFileDownload(payload: DownloadFilePayload) {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.post(
          `${(envConfig.UWSApi as any).host}/api/FileDownload`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'arraybuffer',
          },
        );
        this.status = response?.data?.status || response?.status || null;
        this.downloadFile = response?.data || null;
        const contentDisposition = response.headers['content-disposition'];
        const input = {
          FileMonth: payload.fileMonth,
          FileYear: payload.fileYear,
        };
        const zipFileName = generateZipFileName(input);
        downloadFile(this.downloadFile, zipFileName);
      } catch (error: any) {
        this.status = error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
      }
    },
  },
});

async function downloadFile(responseData: Uint8Array | null, fileName: string) {
  if (responseData) {
    const blob = new Blob([responseData], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
    URL.revokeObjectURL(url);
  }
}

function convertDateToStringFormatYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function generateZipFileName(input: { FileMonth: number; FileYear: number }) {
  const formattedDate = convertDateToStringFormatYYYYMMDD(new Date()).replace(
    /-/g,
    '',
  );
  return `${input.FileMonth.toString().padStart(2, '0')}${input.FileYear}${'_' + formattedDate}`;
}
