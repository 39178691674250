<template>
  <v-navigation-drawer
    v-model="drawer"
    v-if="!isLoading"
    temporary
    style="z-index: 1050"
    :style="{
      margin: 0,
      padding: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      height: '100%',
    }"
  >
    <div class="main-dialog-content">
      <v-overlay
        :model-value="isClickUploadFile && isUploadFile"
        persistent
        contained
        class="custom-overlay"
      >
        <v-progress-circular
          :size="64"
          :width="8"
          color="#1B5FAA"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <div class="detail-contrainer">
        <div class="header-dialog">
          <img
            src="@/assets/icons/ic_upload.svg"
            alt="My Icon"
            width="24"
            height="24"
            style="margin-top: 3px"
          />
          <p
            class="fg-bold"
            style="
              font-size: 20px;
              font-weight: bold;
              line-height: 32px;
              width: 300px;
              margin-left: 8px;
            "
          >
            อัปโหลดตารางการทำงาน
          </p>

          <v-btn
            style="margin-top: 3px; margin-left: 56px"
            icon
            variant="text"
            @click="closeDrawer"
          >
            <v-icon style="width: 24px; height: 24px; color: #1b5faa"
              >mdi-close</v-icon
            >
          </v-btn>
        </div>
        <v-divider
          :thickness="1"
          class="border-opacity-100"
          color="#D7D8D9"
        ></v-divider>

        <div v-if="stateUploadPage == 'process'" class="state-contrainer">
          <div style="flex: 1">
            <div class="project-detail">
              <p
                id="period-detail"
                class="fg-bold"
                style="
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                ประจำรอบ :
                <span
                  class="fg-normal"
                  style="color: #1b5faa; line-height: 24px; font-weight: normal"
                  >{{ currentPeriodText }}</span
                >
              </p>
              <p
                class="fg-bold"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: bold;
                  margin-bottom: 8px;
                "
              >
                โครงการ <span style="color: red">*</span>
              </p>
              <v-autocomplete
                id="autocomplete-project"
                clearable
                density="compact"
                :items="mappedOrganizations"
                v-model="selectedProject"
                variant="outlined"
                item-title="orgNameTH"
                item-value="orgId"
                return-object
                placeholder="เลือกโครงการ"
                style="max-width: 405px !important"
                filterable
              ></v-autocomplete>
            </div>
            <div class="add-file-contrainer">
              <div v-if="fileDoc" class="delete-icon-container">
                <img
                  src="@/assets/icons/ic_delete_line.svg"
                  alt="Delete Icon"
                  width="16"
                  height="16"
                  @click="deleteSelectedFile"
                />
              </div>
              <div>
                <img
                  v-if="uploadedFileName"
                  src="@/assets/icons/xlsx_enable.svg"
                  alt="My Icon"
                  width="80"
                  height="80"
                  style="margin-top: 16px"
                />
                <img
                  v-else="uploadedFileName"
                  src="@/assets/icons/xlsx_disable.svg"
                  alt="My Icon"
                  width="80"
                  height="80"
                  style="margin-top: 16px"
                />
                <p
                  id="file-name"
                  style="
                    font-size: 14px;
                    margin-bottom: 8px;
                    color: #1b5faa;
                    text-decoration: underline;
                  "
                  v-if="uploadedFileName"
                >
                  {{ uploadedFileName }}
                </p>
                <p v-else style="font-size: 14px; margin-bottom: 8px"></p>

                <v-btn
                  id="get-file"
                  v-if="!uploadedFileName"
                  variant="outlined"
                  color="#162E46"
                  style="
                    margin-bottom: 16px;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: bold;
                  "
                  @click="triggerFileInput"
                >
                  เลือกไฟล์จากเครื่อง
                </v-btn>
                <input
                  id="search-file"
                  type="file"
                  ref="fileInput"
                  accept=".xlsx"
                  style="display: none"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </div>

          <div class="btn-upload-contrainer">
            <v-btn
              id="btn-cancel"
              variant="outlined"
              color="#E33E50"
              @click="closeDrawer"
              class="fg-bold"
              style="
                margin-right: 8px;
                width: 198px;
                height: 40px;
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
              "
            >
              ยกเลิก</v-btn
            >
            <v-btn
              id="btn-upload-file-dialog"
              :disabled="!uploadedFileName || !selectedProject || !uploadble"
              :style="
                uploadedFileName && selectedProject
                  ? 'background-color: #1B5FAA;color: #FFFFFF;'
                  : 'background-color: #D7D8D9; opacity: 0.8; color: #A2A4A7;'
              "
              @click="uploadFile"
              class="fg-bold"
              style="
                width: 198px;
                height: 40px;
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
              "
              >อัปโหลด
            </v-btn>
          </div>
        </div>

        <div v-if="stateUploadPage == 'success'" class="state-contrainer">
          <div style="flex: 1">
            <div class="success-upload-contrainer">
              <img
                src="@/assets/icons/ic_xlsx_upload_success.svg"
                alt="My Icon"
                width="120"
                height="120"
              />
              <div>
                <p
                  class="fg-bold"
                  style="font-size: 20px; line-height: 32px; font-weight: bold"
                >
                  อัปโหลดตารางการทำงาน
                </p>
                <p
                  class="fg-bold"
                  style="font-size: 20px; line-height: 32px; font-weight: bold"
                >
                  เสร็จสมบูรณ์ !
                </p>
              </div>

              <div style="margin-top: 8px">
                <p style="font-size: 16px; line-height: 24px; color: #4a4d51">
                  ระบบทำการอัปโหลดข้อมูลตารางการทำงาน
                </p>
                <p style="font-size: 16px; line-height: 24px; color: #4a4d51">
                  เรียบร้อยแล้ว
                </p>
              </div>
            </div>
          </div>
          <div>
            <v-btn
              id="btn-confirm-success"
              color="#1B5FAA"
              style="
                width: 410px;
                height: 40px;
                font-size: 14px;
                margin-left: 16px;
                margin-bottom: 10px;
                font-weight: bold;
              "
              @click="closeDrawer"
              >ตกลง
            </v-btn>
          </div>
        </div>
        <div v-if="stateUploadPage == 'warning'" class="state-contrainer">
          <div class="warning-upload-contrainer">
            <img
              src="@/assets/icons/ic_upload_warning.svg"
              alt="My Icon"
              width="120"
              height="120"
            />
            <div>
              <p style="font-size: 20px; font-weight: bold">
                ยืนยันการอัปโหลดไฟล์
              </p>
            </div>
            <div style="margin-top: 8px">
              <p style="font-size: 16px; color: #4a4d51">
                โครงการนี้มีการอัปโหลดข้อมูลในระบบแล้ว
              </p>
              <p style="font-size: 16px; color: #4a4d51">
                คุณต้องการอัปโหลดไฟล์นี้หรือไม่
              </p>
            </div>
          </div>
          <div class="btn-upload-warning-contrainer">
            <v-btn
              id="btn-back-warning"
              variant="outlined"
              color="#1B5FAA"
              @click="backToPrevious"
              style="
                margin-right: 8px;
                width: 203px;
                height: 40px;
                font-weight: bold;
              "
            >
              กลับหน้าก่อนหน้า</v-btn
            >
            <v-btn
              id="btn-confirm-warning"
              color="#1B5FAA"
              @click="uploadFileDuplicate"
              style="
                width: 203px;
                height: 40px;
                font-size: 14px;
                font-weight: bold;
              "
              >ยืนยันการอัปโหลด
            </v-btn>
          </div>
        </div>
        <div v-if="stateUploadPage == 'error'" class="state-contrainer">
          <div style="flex: 1">
            <div v-if="errorCode == 'default'">
              <div class="error-upload-contrainer">
                <img
                  src="@/assets/icons/ic_xlsx_upload_error.svg"
                  alt="My Icon"
                  width="120"
                  height="120"
                />
                <div>
                  <p
                    class="fg-bold"
                    style="
                      font-size: 20px;
                      line-height: 32px;
                      font-weight: bold;
                    "
                  >
                    ไม่สามารถอัปโหลดไฟล์ได้
                  </p>
                </div>
                <div style="margin-top: 8px">
                  <p style="font-size: 16px; line-height: 24px; color: #4a4d51">
                    {{ errorMessage }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="errorCode == 'fileType'">
              <div class="error-upload-contrainer">
                <img
                  src="@/assets/icons/ic_xlsx_upload_error.svg"
                  alt="My Icon"
                  width="120"
                  height="120"
                />
                <div>
                  <p
                    class="fg-bold"
                    style="
                      font-size: 20px;
                      line-height: 32px;
                      font-weight: bold;
                    "
                  >
                    ไม่สามารถอัปโหลดไฟล์ได้
                  </p>
                  <p
                    style="
                      font-size: 16px;
                      color: #4a4d51;
                      line-height: 24px;
                      margin-top: 8px;
                    "
                  >
                    กรุณาอัปโหลดไฟล์ .xlsx
                  </p>
                </div>
              </div>
              <!--  btn error back -->
            </div>

            <div v-if="errorCode == 'fileSize'">
              <div class="error-upload-contrainer">
                <img
                  src="@/assets/icons/ic_xlsx_upload_error.svg"
                  alt="My Icon"
                  width="120"
                  height="120"
                />
                <div>
                  <p
                    class="fg-bold"
                    style="
                      font-size: 20px;
                      line-height: 32px;
                      font-weight: bold;
                    "
                  >
                    ไม่สามารถอัปโหลดไฟล์ได้
                  </p>
                  <p
                    style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #4a4d51;
                      margin-top: 8px;
                    "
                  >
                    กรุณาอัปโหลดไม่เกิน 4 MB
                  </p>
                </div>
              </div>
              <!--  btn error back -->
            </div>
          </div>
          <div style="margin-bottom: 10px">
            <v-btn
              id="btn-back-error"
              variant="outlined"
              color="#1B5FAA"
              @click="backToPrevious"
              style="
                width: 410px;
                height: 40px;
                font-size: 14px;
                margin-left: 16px;
                font-weight: bold;
              "
            >
              กลับหน้าก่อนหน้า</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  computed,
  onMounted,
  watchEffect,
} from 'vue';
// Watch for changes in drawer state and emit it to the parent
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useProjecOrganizationStore } from '@/stores/ProjecOrganization';
import { useUpLoadFileStore } from '@/stores/UploadFile';
import { UploadListPayload } from '@/models/UploadListPlayload';
import { UploadFileResponse } from '@/models/UploadFileResponse';
import { UploadFilePayload } from '@/models/UploadFilePayload';
import { useOverlayMainLayoutStore } from '@/stores/OverlayMainLayout';
import { useUpLoadListStore } from '@/stores/GetUploadList';
import { useNotificationAppBar } from '@/stores/NotiApp';
import {
  ProjectOrganizationResponse,
  ProjectOrganizationDropdown,
} from '@/models/ProjecOrganizationResponse';
import config from '../../config';
import { useReloadTableUploadStore } from '@/stores/ReloadUploadList';

const envConfig = config();
const storeUploadSetUp = useUpLoadSetUpStore();
const storeProjecOrganization = useProjecOrganizationStore();
const storeUpLoadFile = useUpLoadFileStore();
const overlayMainLayout = useOverlayMainLayoutStore();
const storeUploadList = useUpLoadListStore();
const storeNotificationAppBar = useNotificationAppBar();
const storeReloadTableUpload = useReloadTableUploadStore();
const isClickUploadFile = ref(false);
const isUploadFileSuccess = ref(false);
const currentPeriodText = ref('');
const hasFetchedData = ref(false);
const stateUploadPage = ref('process');
const errorCode = ref('default');
const errorMessage = ref('');
const empCode = sessionStorage.getItem('empCode') || '';
const periodYear = ref<number | null>(null);
const periodMonth = ref<number | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const fileDoc = ref<File | null>(null);
let selectedProject = ref<ProjectOrganizationDropdown | null>(null);
const uploadedFileName = ref<string>('');
const mappedOrganizations = ref<ProjectOrganizationDropdown[]>([]);
const uploadble = ref(false);

const isLoading = computed(
  () =>
    storeUploadSetUp.isLoading ||
    storeProjecOrganization.isLoading ||
    !hasFetchedData,
);

const isUploadFile = computed(() => storeUpLoadFile.isLoading);
const error = computed(
  () => storeUploadSetUp.error || storeProjecOrganization.error,
);

const backToPrevious = async () => {
  stateUploadPage.value = 'process';
  storeUpLoadFile.isClick = false;
};

const validateIsFileDuplicate = async () => {
  const projectName = selectedProject?.value?.projectName || '';
  const currentPeriod = storeUploadSetUp.periodDetail;
  const date = {
    month: Number(currentPeriod?.period?.currentPeriodMonth ?? 0) - 1,
    year: Number(currentPeriod?.period?.currentPeriodYear ?? 0),
  };
  const isDuplicate = storeUploadList.uploadList?.some((item) => {
    return (
      item.projectName == projectName &&
      item.fileMonth == date.month + 1 &&
      item.fileYear == date.year
    );
  });
  return isDuplicate;
};

const uploadFileDuplicate = async () => {
  if (!fileDoc.value) {
    return;
  }

  isClickUploadFile.value = true;
  storeUpLoadFile.isClick = true;

  const payloadUploadFile: UploadFilePayload = {
    projectCode: selectedProject?.value?.orgCode || '',
    fileMonth: periodMonth?.value || null,
    fileYear: periodYear?.value || null,
    createBy: empCode,
    file: fileDoc?.value || null,
  };
  try {
    await storeUpLoadFile.fetchUpLoadFileDetail(payloadUploadFile);

    if (storeUpLoadFile.status != 200) {
      stateUploadPage.value = 'error';
      errorCode.value = 'default';
      errorMessage.value = storeUpLoadFile.message;
      isUploadFileSuccess.value = false;
    } else {
      stateUploadPage.value = 'success';
      isUploadFileSuccess.value = true;
    }
    if (storeUpLoadFile.uploadFile) {
      console.log('File uploaded successfully:', storeUpLoadFile.uploadFile);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    isClickUploadFile.value = false;
    storeUpLoadFile.isClick = false;
  }
};

const uploadFile = async () => {
  if (!fileDoc.value) {
    return;
  }

  let isFileDuplicate = await validateIsFileDuplicate();
  if (isFileDuplicate) {
    stateUploadPage.value = 'warning';
    return;
  }

  isClickUploadFile.value = true;
  storeUpLoadFile.isClick = true;

  const payloadUploadFile: UploadFilePayload = {
    projectCode: selectedProject?.value?.orgCode || '',
    fileMonth: periodMonth?.value || null,
    fileYear: periodYear?.value || null,
    createBy: empCode,
    file: fileDoc?.value || null,
  };
  try {
    await storeUpLoadFile.fetchUpLoadFileDetail(payloadUploadFile);
    if (storeUpLoadFile.status != 200) {
      stateUploadPage.value = 'error';
      errorCode.value = 'default';
      errorMessage.value = storeUpLoadFile.message;
      isUploadFileSuccess.value = false;
    } else {
      stateUploadPage.value = 'success';
      isUploadFileSuccess.value = true;
    }
    if (storeUpLoadFile.uploadFile) {
      console.log('File uploaded successfully:', storeUpLoadFile.uploadFile);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    isClickUploadFile.value = false;
    storeUpLoadFile.isClick = false;
  }
};

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
});

const emit = defineEmits();
const drawer = ref(props.modelValue);
const showDrawer = ref(props.modelValue);

const closeDrawer = () => {
  drawer.value = false;
  if (isUploadFileSuccess.value == true) {
    storeNotificationAppBar.ShowNotification(
      'อัปโหลดตารางการทำงานเรียบร้อยแล้ว',
      'SUCCESS',
    );
    storeReloadTableUpload.Reload();
  }
  isUploadFileSuccess.value = false;
  isClickUploadFile.value = false;
};

watch(
  drawer,
  (newValue) => {
    if (newValue) {
      overlayMainLayout.turnOnOverlay();
    } else {
      overlayMainLayout.turnOffOverlay();
    }
  },
  { immediate: true }, // Trigger the watcher immediately
);
const fetchDetails = async () => {
  if (hasFetchedData.value) return;
  try {
    const setupUpload = storeUploadSetUp.periodDetail;
    uploadble.value = setupUpload?.period?.canUpload || false;
    currentPeriodText.value = setupUpload?.period?.currentPeriodText || '';
    periodYear.value = setupUpload?.period?.currentPeriodYear
      ? parseInt(setupUpload.period.currentPeriodYear, 10)
      : null;
    periodMonth.value = setupUpload?.period?.currentPeriodMonth
      ? parseInt(setupUpload.period.currentPeriodMonth, 10)
      : null;
    await storeProjecOrganization.fetchProjecOrganization();
    if (Array.isArray(storeProjecOrganization?.porjectOrganization)) {
      mappedOrganizations.value =
        storeProjecOrganization.porjectOrganization.map((item) => ({
          orgCode: item.orgCode,
          orgNameTH: `${item.orgNameTH} (${item.projectCode})`,
          projectName: item.orgNameTH,
        }));
    } else {
      mappedOrganizations.value = [];
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    hasFetchedData.value = true;
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const deleteSelectedFile = () => {
  fileDoc.value = null;
  uploadedFileName.value = '';

  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  const maxSizeInBytes = 4 * 1024 * 1024;

  if (file) {
    const validFileType =
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.name.endsWith('.xlsx');

    if (!validFileType) {
      // alert('กรุณาอัปโหลดไฟล์ .xlsx');
      stateUploadPage.value = 'error';
      errorCode.value = 'fileType';
      return;
    }

    if (file.size > maxSizeInBytes) {
      // alert('ขนาดไฟล์เกินกรุณา อัปโหลดไม่เกิน 4 MB');
      stateUploadPage.value = 'error';
      errorCode.value = 'fileSize';
      return;
    }
    // const periodFileName = formatPeriod(period);
    uploadedFileName.value = file.name;
    fileDoc.value = file;
  }
};
onMounted(() => {
  if (window.ResizeObserver) {
    const ro = new ResizeObserver(() => {});
    ro.observe(document.body);
  }
});
watchEffect(() => {
  if (!hasFetchedData.value) {
    fetchDetails();
  }
});

watch(
  () => props.modelValue,
  (newVal) => {
    drawer.value = newVal;
  },
);
watch(drawer, (newVal) => {
  emit('update:modelValue', newVal);
  showDrawer.value = newVal;
});
</script>

<style scoped>
.main-dialog-content {
  background-color: #ffffff;
  overflow: auto;
  width: 450px;
  height: 100vh;
  margin-right: 240px;
  position: fixed;
  top: 0;
  right: 0;
}

.custom-overlay {
  background-color: rgba(
    255,
    255,
    255,
    0.75
  ); /* White overlay with some transparency */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Horizontally center the spinner */
  align-items: center; /* Vertically center the spinner */
}

.detail-contrainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header-dialog {
  display: flex;
  align-items: center;
  padding: 0px 16px;
}
.project-detail {
  padding: 16px;
}

.add-file-contrainer {
  border: 1px dashed #a2a4a7;
  padding: 16px;
  margin: 0 16px 16px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 405px;
  height: 200px;
  position: relative;
}
.delete-icon-container {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.btn-upload-contrainer {
  display: flex;
  margin: 16px;
  margin-top: auto; /* ให้ปุ่มไปที่ด้านล่าง */
}
.state-contrainer {
  display: flex;
  flex-direction: column;
  height: 100vh; /* ให้หน้าเต็มหน้าจอ */
}
.success-upload-contrainer {
  margin: 120px 32px 32px;
}
.warning-upload-contrainer {
  margin: 120px 32px 32px;
  flex: 1;
}
.error-upload-contrainer {
  margin: 120px 32px 32px;
}
.btn-upload-warning-contrainer {
  display: flex;
  margin: 16px;
  margin-top: auto;
}
.v-autocomplete >>> .v-select__selections {
  overflow: hidden;
  width: 405px;
}
</style>
