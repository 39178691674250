import { defineStore } from 'pinia';

export const useNotificationAppBar = defineStore('notificationAppBar', {
  state: () => ({
    isHideNotification: false,
    messageText: '',
    status: 'SUCCESS',
    icon: '',
    color: '',
  }),
  actions: {
    ShowNotification(msg: string, status: 'SUCCESS' | 'ERROR' | 'WRONG') {
      this.messageText = msg;
      this.status = status;

      if (status.toUpperCase() == 'SUCCESS') {
        this.color = '#387469';
      } else if (status.toUpperCase() == 'ERROR') {
        this.color = '#E33E50';
      }
      this.isHideNotification = true;
      setTimeout(() => {
        this.isHideNotification = false;
      }, 3000);
    },
  },
});
