<template>
  <div
    v-if="storeNotificationAppBar.isHideNotification"
    style="position: fixed; width: 100%; top: 0; z-index: 9999"
  >
    <v-alert
      rounded="0"
      :style="{ width: '100%', padding: '10px', height: '44px' }"
      :color="storeNotificationAppBar.color"
    >
      <div style="display: flex; align-items: center; justify-content: start">
        <img
          v-if="storeNotificationAppBar.status == 'SUCCESS'"
          src="@/assets/icons/ic_check_circle_line.svg"
          alt="My Icon"
          width="21"
          height="21"
          style="align-items: center; margin-right: 8px; margin-left: 6px"
        />
        <p class="message-noti">
          {{ storeNotificationAppBar.messageText }}
        </p>
      </div>
    </v-alert>
  </div>
</template>
<script setup lang="ts">
import { useNotificationAppBar } from '@/stores/NotiApp';
const storeNotificationAppBar = useNotificationAppBar();
//storeNotificationAppBar.status = 'SUCCESS';
//storeNotificationAppBar.color = '#387469';
//storeNotificationAppBar.messageText = 'อัปโหลดตารางการทำงานเรียบร้อยแล้ว';
</script>

<style scoped>
.message-noti {
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
</style>
