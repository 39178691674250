import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, unref as _unref, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/icons/ic_upload.svg'
import _imports_1 from '@/assets/icons/ic_delete_line.svg'
import _imports_2 from '@/assets/icons/xlsx_enable.svg'
import _imports_3 from '@/assets/icons/xlsx_disable.svg'
import _imports_4 from '@/assets/icons/ic_xlsx_upload_success.svg'
import _imports_5 from '@/assets/icons/ic_upload_warning.svg'
import _imports_6 from '@/assets/icons/ic_xlsx_upload_error.svg'


const _hoisted_1 = { class: "main-dialog-content" }
const _hoisted_2 = { class: "detail-contrainer" }
const _hoisted_3 = { class: "header-dialog" }
const _hoisted_4 = {
  key: 0,
  class: "state-contrainer"
}
const _hoisted_5 = { style: {"flex":"1"} }
const _hoisted_6 = { class: "project-detail" }
const _hoisted_7 = {
  id: "period-detail",
  class: "fg-bold",
  style: {"font-size":"16px","line-height":"24px","font-weight":"bold","margin-bottom":"16px"}
}
const _hoisted_8 = {
  class: "fg-normal",
  style: {"color":"#1b5faa","line-height":"24px","font-weight":"normal"}
}
const _hoisted_9 = { class: "add-file-contrainer" }
const _hoisted_10 = {
  key: 0,
  class: "delete-icon-container"
}
const _hoisted_11 = {
  key: 0,
  src: _imports_2,
  alt: "My Icon",
  width: "80",
  height: "80",
  style: {"margin-top":"16px"}
}
const _hoisted_12 = {
  key: 1,
  src: _imports_3,
  alt: "My Icon",
  width: "80",
  height: "80",
  style: {"margin-top":"16px"}
}
const _hoisted_13 = {
  key: 2,
  id: "file-name",
  style: {"font-size":"14px","margin-bottom":"8px","color":"#1b5faa","text-decoration":"underline"}
}
const _hoisted_14 = {
  key: 3,
  style: {"font-size":"14px","margin-bottom":"8px"}
}
const _hoisted_15 = { class: "btn-upload-contrainer" }
const _hoisted_16 = {
  key: 1,
  class: "state-contrainer"
}
const _hoisted_17 = {
  key: 2,
  class: "state-contrainer"
}
const _hoisted_18 = { class: "btn-upload-warning-contrainer" }
const _hoisted_19 = {
  key: 3,
  class: "state-contrainer"
}
const _hoisted_20 = { style: {"flex":"1"} }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "error-upload-contrainer" }
const _hoisted_23 = { style: {"margin-top":"8px"} }
const _hoisted_24 = { style: {"font-size":"16px","line-height":"24px","color":"#4a4d51"} }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 2 }
const _hoisted_27 = { style: {"margin-bottom":"10px"} }

import {
  ref,
  watch,
  computed,
  onMounted,
  watchEffect,
} from 'vue';
// Watch for changes in drawer state and emit it to the parent
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useProjecOrganizationStore } from '@/stores/ProjecOrganization';
import { useUpLoadFileStore } from '@/stores/UploadFile';
import { UploadListPayload } from '@/models/UploadListPlayload';
import { UploadFileResponse } from '@/models/UploadFileResponse';
import { UploadFilePayload } from '@/models/UploadFilePayload';
import { useOverlayMainLayoutStore } from '@/stores/OverlayMainLayout';
import { useUpLoadListStore } from '@/stores/GetUploadList';
import { useNotificationAppBar } from '@/stores/NotiApp';
import {
  ProjectOrganizationResponse,
  ProjectOrganizationDropdown,
} from '@/models/ProjecOrganizationResponse';
import config from '../../config';
import { useReloadTableUploadStore } from '@/stores/ReloadUploadList';


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadDialog',
  props: {
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
},
  setup(__props, { emit: __emit }) {

const envConfig = config();
const storeUploadSetUp = useUpLoadSetUpStore();
const storeProjecOrganization = useProjecOrganizationStore();
const storeUpLoadFile = useUpLoadFileStore();
const overlayMainLayout = useOverlayMainLayoutStore();
const storeUploadList = useUpLoadListStore();
const storeNotificationAppBar = useNotificationAppBar();
const storeReloadTableUpload = useReloadTableUploadStore();
const isClickUploadFile = ref(false);
const isUploadFileSuccess = ref(false);
const currentPeriodText = ref('');
const hasFetchedData = ref(false);
const stateUploadPage = ref('process');
const errorCode = ref('default');
const errorMessage = ref('');
const empCode = sessionStorage.getItem('empCode') || '';
const periodYear = ref<number | null>(null);
const periodMonth = ref<number | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const fileDoc = ref<File | null>(null);
let selectedProject = ref<ProjectOrganizationDropdown | null>(null);
const uploadedFileName = ref<string>('');
const mappedOrganizations = ref<ProjectOrganizationDropdown[]>([]);
const uploadble = ref(false);

const isLoading = computed(
  () =>
    storeUploadSetUp.isLoading ||
    storeProjecOrganization.isLoading ||
    !hasFetchedData,
);

const isUploadFile = computed(() => storeUpLoadFile.isLoading);
const error = computed(
  () => storeUploadSetUp.error || storeProjecOrganization.error,
);

const backToPrevious = async () => {
  stateUploadPage.value = 'process';
  storeUpLoadFile.isClick = false;
};

const validateIsFileDuplicate = async () => {
  const projectName = selectedProject?.value?.projectName || '';
  const currentPeriod = storeUploadSetUp.periodDetail;
  const date = {
    month: Number(currentPeriod?.period?.currentPeriodMonth ?? 0) - 1,
    year: Number(currentPeriod?.period?.currentPeriodYear ?? 0),
  };
  const isDuplicate = storeUploadList.uploadList?.some((item) => {
    return (
      item.projectName == projectName &&
      item.fileMonth == date.month + 1 &&
      item.fileYear == date.year
    );
  });
  return isDuplicate;
};

const uploadFileDuplicate = async () => {
  if (!fileDoc.value) {
    return;
  }

  isClickUploadFile.value = true;
  storeUpLoadFile.isClick = true;

  const payloadUploadFile: UploadFilePayload = {
    projectCode: selectedProject?.value?.orgCode || '',
    fileMonth: periodMonth?.value || null,
    fileYear: periodYear?.value || null,
    createBy: empCode,
    file: fileDoc?.value || null,
  };
  try {
    await storeUpLoadFile.fetchUpLoadFileDetail(payloadUploadFile);

    if (storeUpLoadFile.status != 200) {
      stateUploadPage.value = 'error';
      errorCode.value = 'default';
      errorMessage.value = storeUpLoadFile.message;
      isUploadFileSuccess.value = false;
    } else {
      stateUploadPage.value = 'success';
      isUploadFileSuccess.value = true;
    }
    if (storeUpLoadFile.uploadFile) {
      console.log('File uploaded successfully:', storeUpLoadFile.uploadFile);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    isClickUploadFile.value = false;
    storeUpLoadFile.isClick = false;
  }
};

const uploadFile = async () => {
  if (!fileDoc.value) {
    return;
  }

  let isFileDuplicate = await validateIsFileDuplicate();
  if (isFileDuplicate) {
    stateUploadPage.value = 'warning';
    return;
  }

  isClickUploadFile.value = true;
  storeUpLoadFile.isClick = true;

  const payloadUploadFile: UploadFilePayload = {
    projectCode: selectedProject?.value?.orgCode || '',
    fileMonth: periodMonth?.value || null,
    fileYear: periodYear?.value || null,
    createBy: empCode,
    file: fileDoc?.value || null,
  };
  try {
    await storeUpLoadFile.fetchUpLoadFileDetail(payloadUploadFile);
    if (storeUpLoadFile.status != 200) {
      stateUploadPage.value = 'error';
      errorCode.value = 'default';
      errorMessage.value = storeUpLoadFile.message;
      isUploadFileSuccess.value = false;
    } else {
      stateUploadPage.value = 'success';
      isUploadFileSuccess.value = true;
    }
    if (storeUpLoadFile.uploadFile) {
      console.log('File uploaded successfully:', storeUpLoadFile.uploadFile);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    isClickUploadFile.value = false;
    storeUpLoadFile.isClick = false;
  }
};

const props = __props;

const emit = __emit;
const drawer = ref(props.modelValue);
const showDrawer = ref(props.modelValue);

const closeDrawer = () => {
  drawer.value = false;
  if (isUploadFileSuccess.value == true) {
    storeNotificationAppBar.ShowNotification(
      'อัปโหลดตารางการทำงานเรียบร้อยแล้ว',
      'SUCCESS',
    );
    storeReloadTableUpload.Reload();
  }
  isUploadFileSuccess.value = false;
  isClickUploadFile.value = false;
};

watch(
  drawer,
  (newValue) => {
    if (newValue) {
      overlayMainLayout.turnOnOverlay();
    } else {
      overlayMainLayout.turnOffOverlay();
    }
  },
  { immediate: true }, // Trigger the watcher immediately
);
const fetchDetails = async () => {
  if (hasFetchedData.value) return;
  try {
    const setupUpload = storeUploadSetUp.periodDetail;
    uploadble.value = setupUpload?.period?.canUpload || false;
    currentPeriodText.value = setupUpload?.period?.currentPeriodText || '';
    periodYear.value = setupUpload?.period?.currentPeriodYear
      ? parseInt(setupUpload.period.currentPeriodYear, 10)
      : null;
    periodMonth.value = setupUpload?.period?.currentPeriodMonth
      ? parseInt(setupUpload.period.currentPeriodMonth, 10)
      : null;
    await storeProjecOrganization.fetchProjecOrganization();
    if (Array.isArray(storeProjecOrganization?.porjectOrganization)) {
      mappedOrganizations.value =
        storeProjecOrganization.porjectOrganization.map((item) => ({
          orgCode: item.orgCode,
          orgNameTH: `${item.orgNameTH} (${item.projectCode})`,
          projectName: item.orgNameTH,
        }));
    } else {
      mappedOrganizations.value = [];
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    hasFetchedData.value = true;
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const deleteSelectedFile = () => {
  fileDoc.value = null;
  uploadedFileName.value = '';

  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  const maxSizeInBytes = 4 * 1024 * 1024;

  if (file) {
    const validFileType =
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.name.endsWith('.xlsx');

    if (!validFileType) {
      // alert('กรุณาอัปโหลดไฟล์ .xlsx');
      stateUploadPage.value = 'error';
      errorCode.value = 'fileType';
      return;
    }

    if (file.size > maxSizeInBytes) {
      // alert('ขนาดไฟล์เกินกรุณา อัปโหลดไม่เกิน 4 MB');
      stateUploadPage.value = 'error';
      errorCode.value = 'fileSize';
      return;
    }
    // const periodFileName = formatPeriod(period);
    uploadedFileName.value = file.name;
    fileDoc.value = file;
  }
};
onMounted(() => {
  if (window.ResizeObserver) {
    const ro = new ResizeObserver(() => {});
    ro.observe(document.body);
  }
});
watchEffect(() => {
  if (!hasFetchedData.value) {
    fetchDetails();
  }
});

watch(
  () => props.modelValue,
  (newVal) => {
    drawer.value = newVal;
  },
);
watch(drawer, (newVal) => {
  emit('update:modelValue', newVal);
  showDrawer.value = newVal;
});

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (!isLoading.value)
    ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
        key: 0,
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((drawer).value = $event)),
        temporary: "",
        style: _normalizeStyle([{"z-index":"1050"}, {
      margin: 0,
      padding: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      height: '100%',
    }])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_overlay, {
              "model-value": isClickUploadFile.value && isUploadFile.value,
              persistent: "",
              contained: "",
              class: "custom-overlay"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_progress_circular, {
                  size: 64,
                  width: 8,
                  color: "#1B5FAA",
                  indeterminate: ""
                })
              ]),
              _: 1
            }, 8, ["model-value"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[3] || (_cache[3] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: "My Icon",
                  width: "24",
                  height: "24",
                  style: {"margin-top":"3px"}
                }, null, -1)),
                _cache[4] || (_cache[4] = _createElementVNode("p", {
                  class: "fg-bold",
                  style: {"font-size":"20px","font-weight":"bold","line-height":"32px","width":"300px","margin-left":"8px"}
                }, " อัปโหลดตารางการทำงาน ", -1)),
                _createVNode(_component_v_btn, {
                  style: {"margin-top":"3px","margin-left":"56px"},
                  icon: "",
                  variant: "text",
                  onClick: closeDrawer
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { style: {"width":"24px","height":"24px","color":"#1b5faa"} }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_v_divider, {
                thickness: 1,
                class: "border-opacity-100",
                color: "#D7D8D9"
              }),
              (stateUploadPage.value == 'process')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("p", _hoisted_7, [
                          _cache[5] || (_cache[5] = _createTextVNode(" ประจำรอบ : ")),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(currentPeriodText.value), 1)
                        ]),
                        _cache[6] || (_cache[6] = _createElementVNode("p", {
                          class: "fg-bold",
                          style: {"font-size":"14px","line-height":"20px","font-weight":"bold","margin-bottom":"8px"}
                        }, [
                          _createTextVNode(" โครงการ "),
                          _createElementVNode("span", { style: {"color":"red"} }, "*")
                        ], -1)),
                        _createVNode(_component_v_autocomplete, {
                          id: "autocomplete-project",
                          clearable: "",
                          density: "compact",
                          items: mappedOrganizations.value,
                          modelValue: _unref(selectedProject),
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedProject) ? (selectedProject).value = $event : selectedProject = $event)),
                          variant: "outlined",
                          "item-title": "orgNameTH",
                          "item-value": "orgId",
                          "return-object": "",
                          placeholder: "เลือกโครงการ",
                          style: {"max-width":"405px !important"},
                          filterable: ""
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        (fileDoc.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createElementVNode("img", {
                                src: _imports_1,
                                alt: "Delete Icon",
                                width: "16",
                                height: "16",
                                onClick: deleteSelectedFile
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          (uploadedFileName.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                            : (_openBlock(), _createElementBlock("img", _hoisted_12)),
                          (uploadedFileName.value)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(uploadedFileName.value), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_14)),
                          (!uploadedFileName.value)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 4,
                                id: "get-file",
                                variant: "outlined",
                                color: "#162E46",
                                style: {"margin-bottom":"16px","font-size":"12px","line-height":"16px","font-weight":"bold"},
                                onClick: triggerFileInput
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode(" เลือกไฟล์จากเครื่อง ")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("input", {
                            id: "search-file",
                            type: "file",
                            ref_key: "fileInput",
                            ref: fileInput,
                            accept: ".xlsx",
                            style: {"display":"none"},
                            onChange: handleFileUpload
                          }, null, 544)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_v_btn, {
                        id: "btn-cancel",
                        variant: "outlined",
                        color: "#E33E50",
                        onClick: closeDrawer,
                        class: "fg-bold",
                        style: {"margin-right":"8px","width":"198px","height":"40px","font-size":"14px","line-height":"20px","font-weight":"bold"}
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" ยกเลิก")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        id: "btn-upload-file-dialog",
                        disabled: !uploadedFileName.value || !_unref(selectedProject) || !uploadble.value,
                        style: _normalizeStyle([
                uploadedFileName.value && _unref(selectedProject)
                  ? 'background-color: #1B5FAA;color: #FFFFFF;'
                  : 'background-color: #D7D8D9; opacity: 0.8; color: #A2A4A7;'
              , {"width":"198px","height":"40px","font-size":"14px","line-height":"20px","font-weight":"bold"}]),
                        onClick: uploadFile,
                        class: "fg-bold"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("อัปโหลด ")
                        ])),
                        _: 1
                      }, 8, ["disabled", "style"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (stateUploadPage.value == 'success')
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { style: {"flex":"1"} }, [
                      _createElementVNode("div", { class: "success-upload-contrainer" }, [
                        _createElementVNode("img", {
                          src: _imports_4,
                          alt: "My Icon",
                          width: "120",
                          height: "120"
                        }),
                        _createElementVNode("div", null, [
                          _createElementVNode("p", {
                            class: "fg-bold",
                            style: {"font-size":"20px","line-height":"32px","font-weight":"bold"}
                          }, " อัปโหลดตารางการทำงาน "),
                          _createElementVNode("p", {
                            class: "fg-bold",
                            style: {"font-size":"20px","line-height":"32px","font-weight":"bold"}
                          }, " เสร็จสมบูรณ์ ! ")
                        ]),
                        _createElementVNode("div", { style: {"margin-top":"8px"} }, [
                          _createElementVNode("p", { style: {"font-size":"16px","line-height":"24px","color":"#4a4d51"} }, " ระบบทำการอัปโหลดข้อมูลตารางการทำงาน "),
                          _createElementVNode("p", { style: {"font-size":"16px","line-height":"24px","color":"#4a4d51"} }, " เรียบร้อยแล้ว ")
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("div", null, [
                      _createVNode(_component_v_btn, {
                        id: "btn-confirm-success",
                        color: "#1B5FAA",
                        style: {"width":"410px","height":"40px","font-size":"14px","margin-left":"16px","margin-bottom":"10px","font-weight":"bold"},
                        onClick: closeDrawer
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("ตกลง ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (stateUploadPage.value == 'warning')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "warning-upload-contrainer" }, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        alt: "My Icon",
                        width: "120",
                        height: "120"
                      }),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, " ยืนยันการอัปโหลดไฟล์ ")
                      ]),
                      _createElementVNode("div", { style: {"margin-top":"8px"} }, [
                        _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51"} }, " โครงการนี้มีการอัปโหลดข้อมูลในระบบแล้ว "),
                        _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51"} }, " คุณต้องการอัปโหลดไฟล์นี้หรือไม่ ")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_v_btn, {
                        id: "btn-back-warning",
                        variant: "outlined",
                        color: "#1B5FAA",
                        onClick: backToPrevious,
                        style: {"margin-right":"8px","width":"203px","height":"40px","font-weight":"bold"}
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode(" กลับหน้าก่อนหน้า")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        id: "btn-confirm-warning",
                        color: "#1B5FAA",
                        onClick: uploadFileDuplicate,
                        style: {"width":"203px","height":"40px","font-size":"14px","font-weight":"bold"}
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("ยืนยันการอัปโหลด ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (stateUploadPage.value == 'error')
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      (errorCode.value == 'default')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _cache[15] || (_cache[15] = _createElementVNode("img", {
                                src: _imports_6,
                                alt: "My Icon",
                                width: "120",
                                height: "120"
                              }, null, -1)),
                              _cache[16] || (_cache[16] = _createElementVNode("div", null, [
                                _createElementVNode("p", {
                                  class: "fg-bold",
                                  style: {"font-size":"20px","line-height":"32px","font-weight":"bold"}
                                }, " ไม่สามารถอัปโหลดไฟล์ได้ ")
                              ], -1)),
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("p", _hoisted_24, _toDisplayString(errorMessage.value), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (errorCode.value == 'fileType')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[17] || (_cache[17] = [
                            _createElementVNode("div", { class: "error-upload-contrainer" }, [
                              _createElementVNode("img", {
                                src: _imports_6,
                                alt: "My Icon",
                                width: "120",
                                height: "120"
                              }),
                              _createElementVNode("div", null, [
                                _createElementVNode("p", {
                                  class: "fg-bold",
                                  style: {"font-size":"20px","line-height":"32px","font-weight":"bold"}
                                }, " ไม่สามารถอัปโหลดไฟล์ได้ "),
                                _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51","line-height":"24px","margin-top":"8px"} }, " กรุณาอัปโหลดไฟล์ .xlsx ")
                              ])
                            ], -1)
                          ])))
                        : _createCommentVNode("", true),
                      (errorCode.value == 'fileSize')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[18] || (_cache[18] = [
                            _createElementVNode("div", { class: "error-upload-contrainer" }, [
                              _createElementVNode("img", {
                                src: _imports_6,
                                alt: "My Icon",
                                width: "120",
                                height: "120"
                              }),
                              _createElementVNode("div", null, [
                                _createElementVNode("p", {
                                  class: "fg-bold",
                                  style: {"font-size":"20px","line-height":"32px","font-weight":"bold"}
                                }, " ไม่สามารถอัปโหลดไฟล์ได้ "),
                                _createElementVNode("p", { style: {"font-size":"16px","line-height":"24px","color":"#4a4d51","margin-top":"8px"} }, " กรุณาอัปโหลดไม่เกิน 4 MB ")
                              ])
                            ], -1)
                          ])))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_v_btn, {
                        id: "btn-back-error",
                        variant: "outlined",
                        color: "#1B5FAA",
                        onClick: backToPrevious,
                        style: {"width":"410px","height":"40px","font-size":"14px","margin-left":"16px","font-weight":"bold"}
                      }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode(" กลับหน้าก่อนหน้า")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})