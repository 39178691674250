import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/pattern-group.png'
import _imports_1 from '@/assets/icons/sansiri-logo.svg'
import _imports_2 from '@/assets/icons/ic_upload.svg'


const _hoisted_1 = { class: "login-bg" }
const _hoisted_2 = { class: "d-flex flex-column align-center" }
const _hoisted_3 = { class: "div-contrainer" }
const _hoisted_4 = {
  class: "text-start",
  style: {"margin-bottom":"120px"}
}

import { login, logout } from '@/auth/authService';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { msalInstance } from '@/auth/msalInstance';
import { InteractionRequiredAuthError } from '@azure/msal-browser';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const router = useRouter();
const handleLogin = async () => {
  try {
    const loginResponse = await login(); // Call the login function from authService
    console.log('Login successful:', loginResponse);
    router.push('/');
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.log('Interaction is required to complete the login.');
    } else {
      console.error('Login failed:', error);
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, {
      "no-gutters": "",
      "fill-height": "",
      style: {"height":"100vh","margin":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "4" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_0,
              style: {"height":"100vh","width":"450px"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, { class: "col-contrainer" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              class: "d-flex justify-center align-center",
              style: {"height":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "d-flex flex-column align-center",
                  style: {"margin-bottom":"48px"}
                }, {
                  default: _withCtx(() => [
                    _cache[7] || (_cache[7] = _createElementVNode("img", {
                      style: {"width":"220px","height":"30px","margin-bottom":"60px"},
                      src: _imports_1
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                          _cache[4] || (_cache[4] = _createElementVNode("p", null, [
                            _createElementVNode("span", { class: "title-text-bold" }, "Upload"),
                            _createElementVNode("span", { class: "title-text" }, "WorkSchedule")
                          ], -1)),
                          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "sub-text" }, "ระบบอัปโหลดตารางการทำงาน", -1)),
                          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "des-text" }, null, -1)),
                          _createVNode(_component_v_btn, {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleLogin())),
                            variant: "flat",
                            width: "100%",
                            height: "52",
                            color: "#1B5FAA",
                            style: {"margin-top":"48px","font-weight":"bold","font-size":"14px","border-radius":"8px"},
                            id: "btn-login"
                          }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode(" เข้าสู่ระบบ ")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})