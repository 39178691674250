import { defineStore } from 'pinia';

export const useReloadTableUploadStore = defineStore('reloadTableUpload', {
  state: () => ({
    isReload: false,
  }),
  actions: {
    Reload() {
      this.isReload = true;
    },
    ResetReload() {
      this.isReload = false;
    },
  },
});
