import { defineStore } from 'pinia';
import axios from 'axios';
import { UploadList } from '@/models/UploadListResopnse';
import config from '../../config';
import moment from 'moment';
import { StatusCodes as HttpStatus } from 'http-status-codes';
import { UploadListPayload } from '@/models/UploadListPlayload';

export const useUpLoadListStore = defineStore('uploadList', {
  state: () => ({
    uploadList: null as UploadList[] | null,
    status: null as Number | null,
    isLoading: true,
    error: null as string | null,
  }),
  actions: {
    async fetchUpLoadListDetail(payload: UploadListPayload) {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.post(
          `${(envConfig.UWSApi as any).host}/api/FileUploads`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        this.uploadList = response?.data?.data || null;
        this.status = response?.data?.status || response?.status || null;

        if (this.uploadList && Array.isArray(this.uploadList)) {
          this.uploadList = this.uploadList.map((item) => ({
            ...item,
            createDate: formatCreateDate(item.createDate),
            createDateValue: moment(item.createDate).toDate(),
          }));
          this.uploadList.sort(
            (a, b) => b.createDateValue.getTime() - a.createDateValue.getTime(),
          );
        } else {
          this.uploadList = null;
        }
      } catch (error: any) {
        this.status = error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
      }
    },
  },
});

function formatCreateDate(dateString: string): string {
  return moment(dateString).format('DD / MM / YYYY HH:mm');
}
