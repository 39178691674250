import { defineStore } from 'pinia';
import axios from 'axios';
import config from '../../config';
import { ProjectOrganizationResponse } from '@/models/ProjecOrganizationResponse';
import { StatusCodes as HttpStatus } from 'http-status-codes';

export const useProjecOrganizationStore = defineStore('ProjecOrganization', {
  state: () => ({
    porjectOrganization: null as ProjectOrganizationResponse | null,
    status: null as Number | null,
    isLoading: true,
    error: null as string | null,
  }),
  actions: {
    async fetchProjecOrganization() {
      this.isLoading = true;
      this.error = '';
      const envConfig = config();

      try {
        const response = await axios.get(
          `${(envConfig.EmployeeDetail as any).host}/UploadWorkSchedule/Projects`,
          {
            headers: {
              Authorization: (envConfig.EmployeeDetail as any).token as string,
            },
          },
        );
        this.porjectOrganization = response?.data?.respond_data || null;
        this.status = response?.data?.status || response?.status || null;
      } catch (error: any) {
        this.status = error?.status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.error = error?.message || 'unknown error';
      } finally {
        this.isLoading = false;
      }
    },
  },
});
